import {addToToc} from '@/components/TOC/TocStore';

function getToc(vm) {
  const {toc} = vm.$options;

  if (toc) {
    return typeof toc === 'function'
      ? toc.call()
      : toc;
  }
}

const TocAwareSlideMixin = {
  provide: function () {
    return {
      '$currentTocSlide': () => this.currentTocSlide,
      'cts': this.currentTocSlide
    }
  },
  mounted() {
    this.$nextTick(() => {
      const toc = getToc(this) || {};

      this.currentTocSlide = addToToc(
        toc.toc,
        this.$el,
        toc.description,
        toc.subtitle ? toc.subtitle : "",
        toc.options || {}
      );
    })
  },
  data() {
    return {
      currentTocSlide: null
    }
  }
}

export default TocAwareSlideMixin;