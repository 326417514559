export const ContentMoveDirections = {
  DIRECTION_UP: 'UP',
  DIRECTION_DOWN: 'DOWN',
  DIRECTION_LEFT: 'LEFT',
  DIRECTION_RIGHT: 'RIGHT',
}

export const ContentMoveMovement = {
  MOVEMENT_ENTER: 'ENTER',
  MOVEMENT_LEAVE: 'LEAVE'
}

export const ContentMoveTiming = {
  TIMING_BEFORE: 'BEFORE',
  TIMING_AFTER: 'AFTER'
}

class ContentMove {
  static get DIRECTION_UP() {
    return ContentMoveDirections.DIRECTION_UP;
  }

  static get DIRECTION_DOWN() {
    return ContentMoveDirections.DIRECTION_DOWN;
  }

  static get DIRECTION_LEFT() {
    return ContentMoveDirections.DIRECTION_LEFT;
  }

  static get DIRECTION_RIGHT() {
    return ContentMoveDirections.DIRECTION_RIGHT;
  }

  static get MOVEMENT_ENTER() {
    return ContentMoveMovement.MOVEMENT_ENTER;
  }

  static get MOVEMENT_LEAVE() {
    return ContentMoveMovement.MOVEMENT_LEAVE;
  }

  static get TIMING_AFTER() {
    return ContentMoveTiming.TIMING_AFTER;
  }

  static get TIMING_BEFORE() {
    return ContentMoveTiming.TIMING_BEFORE;
  }

  constructor(
    timing,
    direction,
    movement,
    start,
    end,
    startNode,
    endNode,
  ) {
    this._direction = direction;
    this._movement = movement;
    this._timing = timing;
    this._start = start;
    this._end = end;

    this._startNode = startNode;
    this._endNode = endNode;
  }

  direction() {
    return this._direction;
  }

  movement() {
    return this._movement;
  }

  timing() {
    return this._timing;
  }

  start() {
    return this._start;
  }

  end() {
    return this._end;
  }

  startNode() {
    return this._startNode;
  }

  endNode() {
    return this._endNode;
  }

  isLeaving() {
    return this._movement === ContentMoveMovement.MOVEMENT_LEAVE && this._timing === ContentMove.TIMING_BEFORE;
  }

  hasLeft() {
    return this._movement === ContentMove.MOVEMENT_LEAVE && this._timing === ContentMove.TIMING_AFTER;
  }

  isEntering() {
    return this._movement === ContentMove.MOVEMENT_ENTER && this._timing === ContentMove.TIMING_BEFORE;
  }

  hasEntered() {
    return this._movement === ContentMove.MOVEMENT_ENTER && this._timing === ContentMove.TIMING_AFTER;
  }

  fromAbove() {
    return this.direction() === ContentMove.DIRECTION_DOWN;
  }

  fromBelow() {
    return this.direction() === ContentMove.DIRECTION_UP;
  }
}


export default ContentMove;