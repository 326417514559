<template>
  <a-slide
      background-color="#f2f0ef"
      foreground-color="#4f5d72"
      :center="false"
      :toc-width="400"
      :auto-adjust="true"
      sizing="content"
  >
    <div class="content-padded-horizontal">
      <h2>
        Erklärbare KI im Überblick
        <small>Die wichtigsten Fragen und Antworten zu Erklärbarer KI</small>
      </h2>

      <p class="footnote">
        Die dargestellten Studienergebnisse basieren auf einer von Sommer bis Herbst 2020 durchgeführten
        Online-Befragung,
        an der sich 209 Vertreter:innen aus Unternehmen mit KI-Bezug sowie wissenschaftlichen Einrichtungen beteiligt
        haben.
      </p>
      <responsive-tile-container style="padding-left:30px; padding-right: 30px;">
        <icon-text-tile
            :min-height="150"
            :animate="entering"
            title="Einsatz von KI-Modellen und –Verfahren"
            article="story/xaioverview/explainer1.md"
            :header-class="tile.headerClass"
            :content-class="tile.contentClass"
            flow="column"
            text-align="center"
            :modal-size="currentBreakpoint==='small'?'fullscreen':'huge'"
        >
          <template #icon>
            <a-k1-icon :size="72" :animate="true"></a-k1-icon>
          </template>
          <template #head>
          </template>
          Wo werden heute und in Zukunft KI-Modelle und -Verfahren angewendet?
        </icon-text-tile>

        <icon-text-tile
            :min-height="150"
            :animate="entering"
            title="Warum und in welchen Bereichen ist Erklärbare KI wünschenswert?"
            article="story/xaioverview/explainer2.md"
            :header-class="tile.headerClass"
            :content-class="tile.contentClass"
            flow="column"
            text-align="center"
            :modal-size="currentBreakpoint==='small'?'fullscreen':'huge'"
        >
          <template #icon>
            <a-k2-icon :size="72"></a-k2-icon>
          </template>
          <template #head>
          </template>
          Warum und in welchen Bereichen ist Erklärbare KI wünschenswert?
        </icon-text-tile>

        <icon-text-tile
            :min-height="150"
            :animate="entering"
            article="story/xaioverview/explainer3.md"
            title="Zielgruppen von Erklärbarer KI"
            :header-class="tile.headerClass"
            :content-class="tile.contentClass"
            flow="column"
            text-align="center"
            :modal-size="currentBreakpoint==='small'?'fullscreen':'huge'"
        >
          <template #icon>
            <a-k3-icon :size="72"></a-k3-icon>
          </template>
          <template #head>
          </template>
          Für welche Zielgruppen ist Erklärbare KI wichtig?
        </icon-text-tile>

        <icon-text-tile
            :min-height="150"
            :animate="entering"
            title="Auf welche Datentypen soll Erklärbare KI angewendet werden?"
            article="story/xaioverview/explainer4.md"
            :header-class="tile.headerClass"
            :content-class="tile.contentClass"
            flow="column"
            text-align="center"
            :modal-size="currentBreakpoint==='small'?'fullscreen':'huge'"
        >
          <template #icon>
            <a-k4-icon :size="72"></a-k4-icon>
          </template>
          <template #head>
          </template>
          Auf welche Datentypen soll Erklärbare KI angewendet werden?
        </icon-text-tile>

        <icon-text-tile
            :min-height="150"
            :animate="entering"
            title="Umsetzungsmöglichkeiten für Erklärbare KI"
            article="story/xaioverview/explainer5.md"
            :header-class="tile.headerClass"
            :content-class="tile.contentClass"
            flow="column"
            text-align="center"
            :modal-size="currentBreakpoint==='small'?'fullscreen':'huge'"
        >
          <template #icon>
            <a-k5-icon :size="72"></a-k5-icon>
          </template>
          <template #head>
          </template>
          Wie soll die Erklärbarkeit von KI-Modellen dargestellt werden?
        </icon-text-tile>

        <icon-text-tile
            :min-height="150"
            :animate="entering"
            title="Erklärbarkeit vs. Transparenz"
            article="story/xaioverview/explainer6.md"
            :header-class="tile.headerClass"
            :content-class="tile.contentClass"
            flow="column"
            text-align="center"
            :modal-size="currentBreakpoint==='small'?'fullscreen':'huge'"
        >
          <template #icon>
            <a-k6-icon :size="72"></a-k6-icon>
          </template>
          <template #head>
          </template>
          Was ist bei Erklärbarer KI der Unterschied zwischen Erklärbarkeit und Transparenz?
        </icon-text-tile>
      </responsive-tile-container>
    </div>
  </a-slide>
</template>

<script>
import {BrowserContextGetters} from "@/store/BrowserContext";
import TocAwareSlideMixin from "@/components/TOC/mixin/TocAwareSlide";
import IconTextTile from "@/components/Tile/IconTextTile";
import ResponsiveTileContainer from "@/components/Tile/ResponsiveTileContainer";
import AK1Icon from "@/components/Icons/AK1Icon";
import AK2Icon from "@/components/Icons/AK2Icon";
import AK3Icon from "@/components/Icons/AK3Icon";
import AK4Icon from "@/components/Icons/AK4Icon";
import AK5Icon from "@/components/Icons/AK5Icon";
import AK6Icon from "@/components/Icons/AK6Icon";
import ASlide from "@/components/Slide/ASlide";

export default {
  name: "XaiOverview",
  components: {
    ASlide,
    AK6Icon,
    AK5Icon,
    AK4Icon,
    AK3Icon,
    AK2Icon,
    AK1Icon,
    ResponsiveTileContainer,
    IconTextTile,
  },
  mixins: [TocAwareSlideMixin],
  toc: {
    toc: 'main',
    description: 'Erklärbare KI\n im Überblick',
    subtitle: 'Überblick bringt Übersicht',
    options: {
      background: "black"
    }
  },
  computed: {
    ...BrowserContextGetters
  },
  watch: {
    'currentTocSlide.coversScreen'(val) {
      if (val > 30) {
        this.entering = true;
      }
    },
  },
  data() {
    return {
      tocEntry: null,
      entering: false,
      tile: {
        headerClass: 'c-p3',
        contentClass: 'c-s5'
      }
    }
  },
}
</script>

<style scoped lang="scss">
</style>