<template>
  <div
      class="tile interactive-tile"
      :class="tileClass"
      :style="{minHeight: `${flow==='column'?minHeight:100}px`}"
      v-on="$listeners"
      @click="show"
  >
    <div class="icon" :class="iconClass" ref="theicon">
      <slot name="icon">

      </slot>
    </div>
    <div class="content">
      <h4 :class="headerClass" v-if="$slots.head">
        <slot name="head">
        </slot>
      </h4>
      <p :class="contentClass" :style="{textAlign: textAlign}">
        <slot name="default">
        </slot>
      </p>

      <b-modal
          v-model="visible"
          :scrollable="true"
          :centered="true"
          :hide-footer="true"
          :title="title"
          header-class="modal-study-header"
          :size="modalSize"
      >
        <component v-bind:is="modalContentComponent" :file="article"></component>
      </b-modal>
    </div>
  </div>
</template>

<script>
import MarkdownStory from "@/components/MarkdownStory";
import {BModal} from 'bootstrap-vue';
import gsap from "gsap";

export default {
  name: "IconTextTile",
  components: {MarkdownStory, BModal},
  data() {
    return {
      visible: false,
      animation: null
    }
  },
  computed: {
    tileClass() {
      return `flow-${this.flow}`;
    },
    modalContentComponent() {
      if (this.contentType === 'markdown') {
        return MarkdownStory;
      }

      if (this.contentType === 'component') {
        return this.contentComponent;
      }

      console.warn("Can't determine component from ", this.contentType);

      return null;
    }
  },
  props: {
    animate: {
      type: Boolean,
      default: true
    },
    article: {
      type: String,
      required: false,
      description: "If set, make the tile clickable and open up a modal window for the article content"
    },
    contentType: {
      type: String,
      default: () => "markdown"
    },
    contentComponent: {
      type: Object
    },
    title: {
      type: String
    },
    flow: {
      type: String,
      default: "row"
    },
    iconClass: {
      type: String,
    },
    headerClass: {
      type: String,
    },
    contentClass: {
      type: String,
    },
    minHeight: {
      default: 300
    },
    modalSize: {
      type: String,
      validator: (size) => ['huge', 'fullscreen'].indexOf(size) > -1,
      default: () => 'huge'
    },
    textAlign: {
      type: String,
      default: 'justify'
    }
  },
  watch: {
    animate(val) {
      if (val) {
        this.animation.play();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.animation = gsap.from(this.$refs.theicon, {
        ease: "ease5",
        opacity: 0,
        paused: true,
        y: "+15",
        duration: 1
      });
    })
  },
  methods: {
    show() {
      document.body.classList.add("modal-open");
      this.visible = true;
    },
    hide() {
      document.body.classList.remove("modal-open");
      this.visible = false;
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/colors";
@import "../../assets/scss/variables";

.tile .arrow {
  opacity: 0;
  transition: opacity 0.2s $default-ease;
}

.tile:hover .arrow {
  opacity: 1;
}

.tile {
  display: flex;
  transition: box-shadow .8s ease;

  &:hover {
    //box-shadow: 0px -1px 8px rgba(0,0,0,0.2)
  }

  &.flow-row {
    flex-flow: row;
    width: 350px;
    min-height: 150px;

    .icon {
      padding-top: 60px;
      padding-right: 13px;
    }
  }

  &.flow-column {
    width: 250px;
    flex-flow: column;
    text-align: center;

    .icon {
      padding-bottom: 2rem;
    }

    .content h4 {
      font-size: 30px;
      min-height: 60px;
    }

    .content p {
    }
  }

  .breakpoint-large & {
    margin: 12px 40px;
  }

  .breakpoint-medium & {
    margin: 7px 25px;
  }

  .breakpoint-small & {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }

  .content {
    cursor: pointer;
  }

  .content h4 {
    min-height: 48px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .content p {
  }
}

.interactive-tile {
  cursor: pointer;
}
</style>