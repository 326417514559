import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer'; // Optional
import './assets/scss/study.scss';
import Vue from 'vue';

import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faLinkedin,
  faFacebookSquare,
  faXingSquare,
  faTwitterSquare,
  faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons';
import {faEnvelopeSquare} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(faLinkedin, faFacebookSquare, faXingSquare, faTwitterSquare, faYoutubeSquare, faEnvelopeSquare);

Vue.component('fa-icon', FontAwesomeIcon);

import VueSocialSharing from 'vue-social-sharing/src/vue-social-sharing';


import VueMatomo from 'vue-matomo';
import {MatamoConfig} from "@/config/MatamoConfig";

Vue.use(VueMatomo, MatamoConfig);

Vue.use(VueSocialSharing, {
  networks: {
    youtube: 'https://www.youtube.com/channel/UC9SAD0LLULKajvrRlWm4vGg/featured'
  }
});


Vue.config.productionTip = process.env.NODE_ENV === 'development';


import App from './App.vue';

new Vue({
  render: h => h(App),
}).$mount('#app');
