<template>
  <div>
    <slot
        v-bind:entries="filteredToc"
        v-bind:allEntries="fullToc"
        v-bind:activePage="activePage"
        v-bind:jump="(entry) => goToEntry(entry)"
    >
    </slot>
  </div>
</template>

<script>
import {getToc, TocStoreActions, TocStoreGetters} from '@/components/TOC/TocStore';
import {BrowserContextGetters} from "@/store/BrowserContext";

export default {
  name: "TocAwareContainer",
  props: {
    toc: {
      type: String,
      description: "TOC to display",
      required: true
    }
  },
  computed: {
    ...TocStoreGetters,
    filteredToc() {
      let me = this;
      return getToc().filter((e) => {
        // only my elements && only those not visible
        return e.toc === me.toc && !e.options.hideOnToc;
      });
    },
    fullToc() {
      return getToc();
    }
  },
  data() {
    return {
      /**
       * @type {TocEntry[]}
       */
      entries: null
    }
  },
  methods: {
    ...TocStoreActions,
    goToEntry(entry) {
      this.goToTocElement(entry, BrowserContextGetters.currentMenuHeight());
    }
  }
}
</script>

<style scoped>

</style>