<template>
  <div class="scroll-down-container" v-on="$listeners" v-show="hasNextTocElement">
    <div class="scroll-down" style="width: 32px" :class="themeClass">
      <scroll-down-icon ref="theicon" v-on:click="goToTocElement(target)"
      ></scroll-down-icon>
    </div>
  </div>
</template>

<script>
import ScrollDownIcon from "@/assets/icons/scroll-down-new.svg";
import {TocStoreActions, TocStoreGetters} from "@/components/TOC/TocStore";
import gsap from 'gsap';

export default {
  name: "AScrollDownIcon",
  components: {
    ScrollDownIcon
  },
  props: {
    target: {},
    theme: {
      type: String,
      validator: (theme) => ["dark", "light"].indexOf(theme) > -1,
      default: "light",
    },
    animate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...TocStoreGetters,
    themeClass() {
      return `theme-${this.theme}`;
    }
  },
  methods: {
    ...TocStoreActions
  },
  mounted() {
    this.$nextTick(() => {
      if (this.animate) {
        let eleWheel = this.$refs.theicon.querySelectorAll('.mouse-wheel');
        let eleCaret = this.$refs.theicon.querySelectorAll('.scroll-caret');
        let tl = gsap.timeline({repeat: -1});
        tl.to(eleWheel, {duration: 0.5, y: "+=15", repeat: 5, yoyo: true, delay: 2});
        tl.fromTo(eleCaret, {y: "-=2"}, {duration: 0.5, y: "+=12", repeat: 5, yoyo: true, delay: 2});
      }
    });

  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors";

.scroll-down-container {
  position: absolute;
  left: 50%;
  bottom: 10px;
  z-index: 500;
  transition: opacity 0.5s ease-in;

  .scroll-down {
    left: -50%;
    cursor: pointer;
    position: relative;
    text-align: center;
  }
}

.theme-light {
  & .icon-fill {
    fill: white;
  }

  & .icon-stroke {
    stroke: white;
  }
}

.theme-dark {
  & .icon-fill {
    fill: $color-sec-5;
  }

  & .icon-stroke {
    stroke: $color-sec-5;
  }
}

/*
.bg-orange, .bg-black, .bg-on-black {
  .icon-fill {
    fill: $color-white;
  }

  .icon-stroke {
    stroke: $color-white;
  }
}


.bg-white {
  .icon-fill {
    fill: $color-black;
  }

  .icon-stroke {
    stroke: $color-black;
  }
}*/

</style>