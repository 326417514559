const BrowserConfig = {
  // less than
  breakpoints: {
    'small': 576,
    'medium': 1000,
    'large': 9999
  },
  scrollTrigger: 20,
  menuHeight: {
    'small': 65,
    'medium': 65,
    'large': 0
  },
};

export default BrowserConfig;