<template>
  <div id="app" :class="`breakpoint-${breakpointClass}`" :style="{'--scrollbar-width': `${scrollbarWidth}px`}">
    <scrollbar-width-detector v-model="scrollbarWidth"></scrollbar-width-detector>
    <b-container fluid>
      <toc-aware-container toc="main">
        <template v-slot:default="{entries, allEntries, jump, activePage}">
          <vertical-toc-menu
              v-if="!timeForBurger"
              :entries="entries"
              :jump="jump"
              :active-page="activePage">

          </vertical-toc-menu>
          <mobile-menu
              v-if="timeForBurger"
              :visible="timeForBurger && hasBeenScrolled"
              :entries="allEntries"
              :jump="jump"
              :active-page="activePage"
          ></mobile-menu>
        </template>
      </toc-aware-container>

      <start-slide>
      </start-slide>
      <the-study
      >
      </the-study>

      <xai-overview

      >
      </xai-overview>

      <decision-tree

      >
      </decision-tree>

      <use-cases

      >
      </use-cases>

      <conclusion-v2>
      </conclusion-v2>

      <innovation-contest

      >
      </innovation-contest>

      <imprint-footer

      >
      </imprint-footer>
    </b-container>
  </div>
</template>

<script>
import BrowserConfig from "@/config/BrowserConfig";
import {BrowserContextGetters, BrowserContextMutations} from "@/store/BrowserContext";
import {BContainer} from 'bootstrap-vue';
import StartSlide from "./pages/study/StartSlide";
import TheStudy from "@/pages/study/TheStudy";
import XaiOverview from "@/pages/study/XaiOverview";
import DecisionTree from "@/pages/study/DecisionTree";
import UseCases from "@/pages/study/UseCases";
import ImprintFooter from "@/pages/study/ImprintFooter";
import InnovationContest from "@/pages/study/InnovationContest";
import TocAwareContainer from "@/components/TOC/TocAwareContainer";
import VerticalTocMenu from "@/components/Menu/VerticalTocMenu";
import MobileMenu from "@/components/Menu/MobileMenu";
import ScrollbarWidthDetector from "@/components/ScrollbarWidthDetector";
import ConclusionV2 from "@/pages/study/ConclusionV2";

export default {
  name: 'App',
  components: {
    ConclusionV2,
    ScrollbarWidthDetector,
    MobileMenu,
    VerticalTocMenu,
    TocAwareContainer,
    InnovationContest,
    ImprintFooter,
    UseCases,
    DecisionTree,
    XaiOverview,
    TheStudy,
    StartSlide,
    BContainer,
  },
  data() {
    return {
      breakpointClass: null,
      scrollbarWidth: 0
    }
  },
  computed: {
    ...BrowserContextGetters
  },
  watch: {
    hasBeenScrolled: {
      immediate: true,
      handler: function (val) {
        if (!val) {
          window.addEventListener('scroll', this.scrollTriggered);
        }
      }
    }
  },
  methods: {
    ...BrowserContextMutations,
    onAppResize() {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      this.setCurrentDocumentHeight((document.height !== undefined) ? document.height : document.body.offsetHeight);
      this.setCurrentWindowHeight(window.innerHeight);
      this.setCurrentWindowWidth(window.innerWidth);
      Object.keys(BrowserConfig.breakpoints).find((bp) => {
        if (vw < BrowserConfig.breakpoints[bp]) {
          this.setCurrentBreakpoint(bp);
          this.breakpointClass = bp;
          if (!document.body.classList.contains(`breakpoint-${bp}`)) {
            document.body.classList.remove('breakpoint-large', 'breakpoint-medium', 'breakpoint-small');
            document.body.classList.add(`breakpoint-${bp}`);
          }

          return true;
        }
      })
    },
    scrollTriggered() {
      if (window.scrollY > BrowserConfig.scrollTrigger) {
        BrowserContextMutations.setHasBeenScrolled(true);

        window.removeEventListener('scroll', this.scrollTriggered);
      }
    }
  },
  mounted() {
    document.defaultView.addEventListener('resize', this.onAppResize);
    window._paq.push(['trackPageView']);

    this.$nextTick(() => {
      setTimeout(() => {
        window.scrollTo({top: 1});
        setTimeout(() => {
          window.scrollTo({top: 0});
        }, 50);
      }, 50);
    });

    this.onAppResize();
  },
  beforeDestroy() {
    document.defaultView.removeEventListener('resize', this.onAppResize);
  }
}
</script>

<style lang="scss">
</style>
