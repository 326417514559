<template>
  <section
      class="a-slide"
      :class="` ${fix?'mof':''}`"
      :style="{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        }"
  >
    <div
        ref="theContentContainer"
        :class="`sizing-${this.sizing} ${center?'center-content':''} ${padded?'content-padded':''}`"
        :style="{
        maxWidth: autoAdjust?`${maxWidth}px`:'unset',
        marginLeft: autoAdjust?'auto':0,
        marginRight: autoAdjust?currentRightMargin:0
        }"
    >
      <slot name="default">

      </slot>

      <a-scroll-down-icon
          v-if="showScrollDownIcon && currentTocSlide"
          class="scroll-down-container"
          :target="currentTocSlide.siblings.next()"
          :theme="currentTocSlide.options.background==='light'?'dark':'light'"
      >
      </a-scroll-down-icon>
    </div>
  </section>
</template>

<script>
import AScrollDownIcon from "@/components/Icons/AScrollDownIcon";
import {BrowserContextGetters} from "@/store/BrowserContext";

export default {
  name: "ASlide",
  components: {AScrollDownIcon},
  inject: [
    '$currentTocSlide'
  ],
  props: {
    backgroundColor: {
      description: "Background color",
      type: String,
      default: () => 'inherit'
    },
    foregroundColor: {
      description: "Dominant foreground color",
      type: String,
      default: () => 'inherit'
    },
    padded: {
      default: true
    },
    maxWidth: {
      type: Number,
      default: 1500
    },
    tocWidth: {
      type: Number,
      default: 520
    },
    sizing: {
      description: "Height handling; screen == at least 100vh, content == height according slide content",
      validator: (height) => ['screen', 'content'].indexOf(height) !== -1,
      default: 'screen',
      type: String
    },
    scrollIndicator: {
      description: "Show the scrollindicator",
      type: Boolean,
      default: false
    },
    center: {
      description: "Center content - beware, only works with fixed height",
      type: Boolean,
      default: () => false
    },
    autoAdjust: {
      type: Boolean,
      default: true,
      description: "If true, adjust size to make place for the TOC"
    },
    fix: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentRightMargin: 'auto'
    }
  },
  computed: {
    ...BrowserContextGetters,
    maxWindowWidth() {
      return this.maxWidth + this.tocWidth;
    },
    currentTocSlide() {
      return this.$currentTocSlide();
    },
    showScrollDownIcon() {
      return this.scrollIndicator &&
          this.currentBreakpoint !== 'small' &&
          this.currentTocSlide && typeof this.currentTocSlide.siblings.next === 'function';
    }
  },
  watch: {
    currentWindowWidth: {
      immediate: true,
      handler() {
        if (!this.autoAdjust) {
          return;
        }
        if (this.currentBreakpoint === 'large') {
          if (this.currentWindowWidth - this.maxWidth < 2 * this.tocWidth) {
            this.currentRightMargin = `${this.tocWidth}px`;
          } else {
            this.currentRightMargin = 'auto';
          }
        } else {
          this.currentRightMargin = 'auto';
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.modal-open .mof {
  //padding-right: var(--scrollbar-width);
}

.a-slide {
  // width: 100vw;
  position: relative;
  z-index: 1;
  transition: max-height .4s ease-out;

  .sizing-screen {
    min-height: 100vh;
  }

  & .center-content {

    .sizing-screen & {
      min-height: 100vh;
    }

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
</style>