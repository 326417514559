export function nl2br(inStr) {
  if (typeof inStr !== "string") {
    return inStr;
  }

  return inStr.replace("\n", "<br />");
}

export function nl2none(inStr) {
  if (typeof inStr !== "string") {
    return inStr;
  }

  return inStr.replace("\n", "");
}