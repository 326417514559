<template>
  <a-slide
      background-color="#f2f0ef"
      foreground-color="#4f5d72"
      :toc-width="400"
      :auto-adjust="true"
      :center="false"
      sizing="content"
  >
    <div class="content-padded-horizontal">
      <h2>
        Use Cases
        <small>
          So kann es gehen: Vier Beispiele aus der Praxis
        </small>
      </h2>

      <p>
        Laut Studie gehört die Gesundheitswirtschaft zu den Branchen, in der sich KI ohne eine hinreichende
        Erklärbarkeit
        nicht etablieren wird (s. Use Cases 1 und 2). Auch für weitere Branchen wie die Produktionswirtschaft oder
        die Prozessindustrie ist Erklärbarkeit meist unumgänglich (s. Use Cases 3 und 4).
      </p>
      <p>
        Die Analyse der folgenden Beispiele zeigt: Geeignete Erklärungsstrategien hängen immer von den Zielgruppen, den verwendeten Datentypen und dem
        verwendeten KI-Modell ab.
      </p>

      <tile-container style="margin-top: 3rem">
        <icon-text-tile
            title="USE CASE 1 - KI-gestützte Anomalie-Erkennung"
            :animate="entering"
            flow="column"
            content-type="component"
            :content-component="UseCase1Component"
            :icon-class="tile.iconClass"
            :header-class="tile.headerClass"
            :content-class="tile.contentClass"
            modal-size="fullscreen"
            text-align="center"
        >
          <template #icon>
            <lottie-icon
                style="vertical-align: middle"
                icon="mikroskop"
                animate="hover"
                size="large"
            ></lottie-icon>
          </template>
          <template #head>

          </template>
          Use Case 1: KI-gestützte Anomalie-Erkennung
        </icon-text-tile>

        <icon-text-tile
            title="USE CASE 2 - KI-gestützte Textanalyse von Arztbriefen"
            :animate="entering"
            content-type="component"
            :content-component="UseCase2Component"
            flow="column"
            :header-class="tile.headerClass"
            :content-class="tile.contentClass"
            modal-size="fullscreen"
            text-align="center"
        >
          <template #icon>
            <lottie-icon
                style="vertical-align: middle"
                icon="paper"
                animate="hover"
                size="large"
            ></lottie-icon>
          </template>
          <template #head>

          </template>
          Use Case 2: KI-gestützte Textanalyse von Arzt&shy;briefen
        </icon-text-tile>

        <icon-text-tile
            title="USE CASE 3 - KI-basierte Maschinenüberwachung"
            :animate="entering"
            content-type="component"
            :content-component="UseCase3Component"
            flow="column"
            :header-class="tile.headerClass"
            :content-class="tile.contentClass"
            modal-size="fullscreen"
            text-align="center"
        >
          <template #icon>
            <lottie-icon
                style="vertical-align: middle"
                icon="robot"
                animate="hover"
                size="large"
            ></lottie-icon>
          </template>
          <template #head>

          </template>
          Use Case 3: KI-basierte Maschinen&shy;überwachung
        </icon-text-tile>

        <icon-text-tile
            title="USE CASE 4 - KI zur Planung, Analyse und Regelung in der Prozessindustrie"
            :animate="entering"
            content-type="component"
            :content-component="UseCase4Component"
            flow="column"
            :header-class="tile.headerClass"
            :content-class="tile.contentClass"
            modal-size="fullscreen"
            text-align="center"
        >
          <template #icon>
            <lottie-icon
                style="vertical-align: middle"
                icon="milch"
                animate="hover"
                size="large"
            ></lottie-icon>
          </template>
          <template #head>

          </template>
          Use Case 4: KI zur Planung, Analyse und Regelung in der Prozess&shy;industrie
        </icon-text-tile>
      </tile-container>
    </div>
  </a-slide>
</template>

<script>
import TocAwareSlideMixin from "@/components/TOC/mixin/TocAwareSlide";
import TileContainer from "@/components/Tile/TileContainer";
import IconTextTile from "@/components/Tile/IconTextTile";

import UseCase1 from "@/pages/study/UseCases/UseCase1";
import UseCase2 from "@/pages/study/UseCases/UseCase2";
import UseCase3 from "@/pages/study/UseCases/UseCase3";
import UseCase4 from "@/pages/study/UseCases/UseCase4";
import LottieIcon from "@/components/Lottie/LottieIcon";
import ASlide from "@/components/Slide/ASlide";

export default {
  name: "UseCases",
  components: {ASlide, LottieIcon, IconTextTile, TileContainer},
  mixins: [TocAwareSlideMixin],
  toc: {
    toc: 'main',
    description: 'Use Cases',
    options: {
      background: "black"
    }
  },
  computed: {
    UseCase1Component() {
      return UseCase1;
    },
    UseCase2Component() {
      return UseCase2;
    },
    UseCase3Component() {
      return UseCase3;
    },
    UseCase4Component() {
      return UseCase4;
    }
  },
  watch: {
    'currentTocSlide.coversScreen'(val) {
      if (val > 30) {
        this.entering = true;
      }
    }
  },
  data() {
    return {
      tocEntry: null,
      entering: false,
      tile: {
        headerClass: "c-s5 heading-reduced ",
        contentClass: "c-s5",
        iconClass: "reduced-padding",
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/colors';

.green-me {
  font-weight: 700;
  color: $color-pri-3;
}
</style>