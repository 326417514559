<template>
  <div id="particle-container" ref="particleContainer">

  </div>
</template>

<script>
import {tsParticles} from "tsparticles";

export default {
  name: "ParticlesPlayer",
  props: {
    particleFile: {
      description: "Path to a particles.json file",
      type: String,
      required: true
    },
    play: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  /**
   * storing particleInstance out of the data-object as reactivity renders the site useless
   * @type {Container}
   */
  particleInstance: null,
  beforeDestroy() {
    this.clearParticles();
  },
  methods: {
    clearParticles() {
      if (this.$options.particleInstance) {
        this.$options.particleInstance.destroy();
        this.$options.particleInstance = null;
      }
    },
    /**
     *
     * @param {Container} instance
     */
    setPlay(instance) {
      if (this.play) {
        instance.play();
      } else {
        instance.pause();
      }
    },
    loadParticles(particleFile) {
      if (this.loading) {
        return;
      }
      this.loading = false;

      this.clearParticles();

      tsParticles
          .loadJSON('particle-container', particleFile)
          .then((instance) => {
            this.$options.particleInstance = instance;

            this.setPlay(this.$options.particleInstance);

            this.loading = false;
          });
    }
  },
  watch: {
    particleFile: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          this.loadParticles(val);
        });
      }
    },
    play() {
      if (this.$options.particleInstance) {
        this.setPlay(this.$options.particleInstance);
      }
    }
  }

}
</script>

<style scoped>

#particle-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

</style>