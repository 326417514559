<template>
  <a-slide
      background-color="#4f5d72"
      foreground-color="white"
      sizing="content"
      :toc-width="400"
      :auto-adjust="false"
      :scroll-indicator="false"
      :padded="false"
  >
    <div class="imprint-container content-padded-horizontal">
      <div class="links">
        Begleitforschung KI-Innovationswettbewerb<br/>
        iit-Institut für Innovation und Technik <br/>in der VDI / VDE Innovation + Technik GmbH<br/>
        Dr. Steffen Wischmann<br/>
        <br/>
        Steinplatz 1<br/>10623 Berlin<br/>
      </div>
      <div class="mitte">
        <share-network v-for="(network,k) in shareOn" v-bind:key="k"
                       :network="network.network"
                       :url="sharing.url"
                       :title="sharing.title"
        >
          <span class="network-icon-container">
          <fa-icon
              class="network-icon"
              :icon="[network.style, network.icon]"
              size="2x"
          ></fa-icon>
            </span>

        </share-network>
      </div>
      <div class="rechts">
        <ul>
          <li>
            <a href="#" @click.prevent="loadPage('imprint')">Impressum</a>
          </li>
          <li>
            <a href="#" @click.prevent="loadPage('privacy')">Datenschutzhinweis</a>
          </li>
          <li>
            <a href="#" @click.prevent="loadPage('disclaimer')">Disclaimer</a>
          </li>
        </ul>
      </div>

      <b-modal
          v-model="modalVisible"
          :scrollable="true"
          :centered="true"
          :hide-footer="true"
          :title="title"
          header-class="modal-study-header"
          size="huge"
      >
        <MarkdownStory :file="article"></MarkdownStory>
      </b-modal>
    </div>
  </a-slide>
</template>

<script>
import TocAwareSlideMixin from "@/components/TOC/mixin/TocAwareSlide";
import ASlide from "@/components/Slide/ASlide";
import MarkdownStory from "@/components/MarkdownStory";
import {BModal} from 'bootstrap-vue';

export default {
  name: "ImprintFooter",
  components: {MarkdownStory, ASlide, BModal},
  mixins: [TocAwareSlideMixin],
  toc: {
    toc: 'main',
    description: 'Impressum',
    options: {
      hidden: true,
      hideOnToc: true
    }
  },
  data() {
    return {
      tocEntry: null,
      article: null,
      title: null,
      modalVisible: false,
      shareOn: [
        {
          network: 'email',
          icon: 'envelope-square',
          style: 'fas'
        },
        {
          network: 'youtube',
          icon: 'youtube-square',
          style: 'fab'
        },
        {
          network: 'linkedin',
          icon: 'linkedin',
          style: 'fab'
        },
        {
          network: 'facebook',
          icon: 'facebook-square',
          style: 'fab'
        },

        {
          network: 'xing',
          icon: 'xing-square',
          style: 'fab'
        },
        {
          network: 'twitter',
          icon: 'twitter-square',
          style: 'fab'
        }
      ],
      sharing: {
        url: 'https://erklaerbare-ki.digital/',
        title: 'Erklärbare KI − Anforderungen, Anwendungsfälle und Lösungen',
        description: 'Damit KI-Produkte akzeptiert werden, müssen die Entscheidungen der KI erklärbar sein. Eine BMWK-Studie zeigt potenzielle Erklärungsstrategien auf',
      }
    }
  },
  methods: {
    loadPage(story) {
      switch (story) {
        case 'imprint':
          this.article = 'meta/imprint.md';
          this.title = 'Impressum';
          this.modalVisible = true;
          break;
        case 'privacy':
          this.article = 'meta/privacy.md';
          this.title = 'Datenschutzerklärung';
          this.modalVisible = true;
          break;
        case 'disclaimer':
          this.article = 'meta/disclaimer.md';
          this.title = 'Haftungsausschluss';
          this.modalVisible = true;
          break;
        default:
          console.warn(`unknown story '${story}'`);
          this.article = null;
          this.title = null;
          this.modalVisible = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

.network-icon-container {
  padding-left: 1rem;
  padding-right: 1rem;

  .network-icon {
    display: inline-block;
    color: white;
  }
}

.imprint-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: $font-size-base * 0.9;
  font-weight: 100;
  padding-top: 1rem;
  padding-bottom: 1rem;

  & a:hover,
  & a:focus,
  & a:visited,
  & a {
    text-decoration: none;
    color: inherit;
  }

  & > * {
    flex: 1 0 33%;
  }

  & ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
  }

  .links {
  }

  .mitte {
    text-align: center;
    align-self: center;
  }

  .rechts {
    text-align: right;
  }

}

// imprint in row and centered
@media (max-width: 830px) {
  .imprint-container {
    flex-flow: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;

    & > div {
      margin-bottom: 2rem;
    }

    & ul {
      text-align: center;
      padding-left:0;
      & li {
        margin-bottom: 1rem;
      }
    }
  }
}

</style>