<template>
  <div class="scrollbar-measure" v-if="!measured" ref="scrollDiv">

  </div>
</template>

<script>
/**
 * Based on the idea from https://davidwalsh.name/detect-scrollbar-width
 */
export default {
  name: "ScrollbarWidthDetector",
  data() {
    return {
      measured: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      const scrollDiv = this.$refs.scrollDiv;
      let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

      this.$emit('input', scrollbarWidth);
    });
  }
}
</script>

<style scoped>
.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}
</style>