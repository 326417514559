<template>
  <div class="use-case-container">
    <b-row>
      <b-col cols="12" col-xl="6">
        <div class="logo-container">
          <div class="symbol">
            <lottie-icon
                style="vertical-align: middle"
                :icon="icon"
                animate="hover"
                size="medium"
            ></lottie-icon>
          </div>
          <div class="heading">
            <h3 class="upper">
              Use case
              <small>KI-gestützte Maschinenzustandsüberwachung</small>
            </h3>
          </div>
        </div>


      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6">
        <table class="use-case-table mb-5">
          <tbody>
          <tr>
            <th>Typ</th>
            <td>Erkennung von Anomalie(n) zum Zweck der Instandhaltungsplanung, Entscheidungsunterstützung</td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <th>Kritikalität</th>
            <td>Hoch (funktionale Sicherheit, Wirtschaftlichkeit)</td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <th>Datentypen</th>
            <td>Numerische Daten (Sensordaten, Betriebsparameter), Textdaten (Fehlercodes, Maschinen-Logdateien)</td>
          </tr>
          </tbody>

          <tbody>
          <tr>
            <th rowspan="2">Typische KI-Modelle</th>
            <td>
              <div class="cell-head">
                Ansatz 1<small>:</small>
              </div>
              Bayes-Netze
            </td>
          </tr>
          <tr>
            <td>
              <div class="cell-head">
                Ansatz 2<small>:</small>
              </div>
              Maschinelles Lernen auf Basis von Wissensgraphen
            </td>
          </tr>
          </tbody>
          <tbody class="separator-top">
          <tr>
            <th rowspan="2">
              (Wichtigste) Zielgruppen<br/>
              <small>und jeweils übergeordnete Ziele für die Nutzung erklärbarer KI</small>
            </th>
            <td>
              <div class="cell-head">
                Domänenexpert:innen<small>:</small>
              </div>
              Kausalitätsbeziehungen finden, Konfidenz (Robustheit, Stabilität) bestimmen, Interaktionsmöglichkeiten
              verbessern;
            </td>
          </tr>
          <tr>
            <td class="separator-top">
              <div class="cell-head">
                Entwickler:innen<small>:</small>
              </div>
              Konfidenz (Robustheit, Stabilität) bestimmen, Interaktionsmöglichkeiten verbessern;
            </td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <th>Konkrete Anforderungen<br/>
              an Erklärbarkeit
            </th>
            <td>
              <div class="cell-head">
                Domänenexpert:innen <small>und</small> Entwickler:innen
              </div>
              Bewertung (Plausibilität, statistische Bewertung) der Güte der Modelle (betrifft Teilaspekte globaler
              Erklärbarkeit), Bewertung der Einzelentscheidung (lokale Erklärbarkeit)

            </td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <th rowspan="2">Geeignete Erklärungsstrategien</th>
            <td>
              <div class="cell-head">
                Ansatz 1<small>:</small>
              </div>
              <ul>
                <li>Verwendung und Anpassung von Surrogat-Modellen (Modellplausibilität),</li>
                <li>Extraktion statistischer Güteparameter (Bayes'sche Statistik)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <div class="cell-head">
                Ansatz 2<small>:</small>
              </div>
              Natürlichsprachliche Erklärungen (Wissensgraphen)
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
      <b-col cols="12" lg="6" class="use-case-text">
        <markdown-it-vue-light
            ref="markdown"
            :content="content"
        >

        </markdown-it-vue-light>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BRow, BCol} from 'bootstrap-vue';
import MarkdownItVueLight from '@/components/MarkdownItVueLighter/markdown-it-vue-light'
import LottieIcon from "@/components/Lottie/LottieIcon";

require('markdown-it-footnote');

import axios from "axios";

export default {
  name: "UseCase3",
  components: {
    BRow, BCol,
    MarkdownItVueLight,
    LottieIcon
  },
  data() {
    return {
      content: "",
      icon: "robot",
      file: "story/usecase/usecase3.md",
    }
  },
  mounted() {
    axios.get(this.file).then(({data}) => {
      return this.content = data;
    });
  },
}
</script>

<style scoped lang="scss">

</style>