<template>
  <div class="lottie-icon-container">
    <lottie-animation
        @DOMLoaded="onAnimationItem"
        @mouseenter.native="mouseenter"
        @mouseleave.native="mouseleave"
        :path="iconPath(icon)"
        :width="iconSize"
        :height="iconSize"
        :auto-play="autoplay"
        :loop="loop"
    >

    </lottie-animation>
  </div>
</template>

<script>
import LottieAnimation from "@/components/Lottie/LottieAnimation";

export default {
  name: "LottieIcon",
  components: {LottieAnimation},
  props: {
    icon: {
      type: String,
      required: true,
      description: "relative icon path - without extension"
    },
    size: {
      type: String,
      required: true,
      validator: (size) => ['small', 'medium', 'large'].indexOf(size) > -1,
      default: "small"
    },
    animate: {
      type: String,
      default: "loop",
      validator: (ele) => ["loop", "hover", "still"].indexOf(ele) > -1
    }
  },
  data() {
    return {
      loopDelay: 2000,
      animationItem: null,
    }
  },
  beforeDestroy() {
    if (this.animationItem) {
      this.animationItem.removeEventListener('complete', this.onComplete);
    }
  },
  computed: {
    autoplay() {
      return this.animate === 'loop';
    },
    loop() {
      return (this.animate === 'loop');
    },
    iconSize() {
      switch (this.size) {
        case 'small':
          return 75;
        case 'medium':
          return 110;
        case 'large':
          return 198;
        default:
          throw new Error(`Invalid size '${this.size}' for a lottie-icon`);
      }
    }
  },
  methods: {
    iconPath(icon) {
      return `ico/${icon}.json`;
    },
    /**
     * @param {AnimationItem} animationItem
     */
    onAnimationItem(animationItem) {
      this.animationItem = animationItem;
      animationItem.addEventListener('complete', this.onComplete);
    },
    mouseenter() {
      if (this.animate === 'hover') {
        this.animationItem.play();
      }
    },
    mouseleave() {
    },
    onComplete() {
      if (this.animate === 'hover') {
        this.animationItem.goToAndStop(0);
      }
    }
  }
}
</script>

<style scoped>
.lottie-icon-container {
  display: inline-block;
}
</style>