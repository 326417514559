import Vue from 'vue';

import {BrowserContextGetters} from '@/store/BrowserContext';
import {ContentMoveDirections, ContentMoveMovement} from "@/components/TOC/ContentMove";

const tocs = Vue.observable({
  /**
   * @type {TocEntry[]}
   */
  entries: [],
  /**
   * @type {TocEntry}
   */
  activePage: null
});

/**
 *
 * @param {String} toc
 * @param {Element} element
 * @param {String} description
 * @param {String} subtitle
 * @param {Object} options
 */
export function addToToc(toc, element, description, subtitle, options) {
  /**
   * @type {TocEntry}
   */
  const entry = {
    // make sure element will not be "reactiv"-ated
    element: () => element,
    observer: () => observer,
    active: false,
    options: Object.assign({
      hidden: false,
      hideOnToc: false,
      background: "dark"
    }, options || {}),
    percent: 0,
    coversScreen: 0,
    entered: false,
    hasEntered: false,
    coversScreenClear: 0,
    description: description,
    subtitle: subtitle,
    toc: toc,
    direction: null,
    movement: null,
    detail: {
      after: 0,
      until: 0,
      _pY: 0,
      _pR: 0
    },
    siblings: {
      prev: null,
      next: null
    }
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((observerEntry) => {
      let perc = _calculateVisibility(observerEntry.target);

      const currentY = observerEntry.boundingClientRect.y
      const currentRatio = observerEntry.intersectionRatio
      const isIntersecting = observerEntry.isIntersecting

      // Scrolling down/up
      if (currentY < entry.detail._pY) {
        entry.direction = ContentMoveDirections.DIRECTION_DOWN;
        if (currentRatio > entry.detail._pR && isIntersecting) {
          entry.movement = ContentMoveMovement.MOVEMENT_ENTER;
        } else {
          entry.movement = ContentMoveMovement.MOVEMENT_LEAVE;
        }
      } else if (currentY > entry.detail._pY && isIntersecting) {
        entry.direction = ContentMoveDirections.DIRECTION_UP;
        if (currentRatio < entry.detail._pR) {
          entry.movement = ContentMoveMovement.MOVEMENT_LEAVE;
        } else {
          entry.movement = ContentMoveMovement.MOVEMENT_ENTER;
        }
      }

      if (isIntersecting) {
        entry.percent = perc.percent || 0;
        const isActive = isActivePage(entry)
        if (isActive) {
          tocs.activePage = entry;
        }
        entry.active = isActive;
        entry.coversScreen = perc.coversScreen;
        entry.detail.until = perc.before;
        entry.detail.after = perc.after;

        if (!entry.hasEntered &&
          entry.movement === ContentMoveMovement.MOVEMENT_ENTER &&
          entry.direction === ContentMoveDirections.DIRECTION_DOWN &&
          entry.coversScreen >= 25) {
          entry.entered = true;
          entry.hasEntered = true;
        }
      } else {
        entry.active = false;
        entry.percent = 0;
        entry.coversScreen = perc.coversScreen;
        entry.detail.until = perc.before;
        entry.detail.after = perc.after;
      }

      entry.coversScreenClear = Math.min(Math.ceil(entry.coversScreen), 100);

      entry.detail._pY = currentY
      entry.detail._pR = currentRatio
    })
  }, {
    threshold: [
      0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5,
      0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1
    ]
  });

  observer.observe(entry.element());
  if (tocs.entries.length > 0) {
    // store next
    const prev = tocs.entries[tocs.entries.length - 1];
    prev.siblings.next = () => entry;
    entry.siblings.prev = () => prev;
  }
  tocs.entries.push(entry);

  return entry;
}

function isActivePage(entry) {
  if (entry.movement === ContentMoveMovement.MOVEMENT_ENTER) {
    if (entry.direction === ContentMoveDirections.DIRECTION_DOWN && entry.coversScreenClear > 50) {
      return true;
    }

    if (entry.direction === ContentMoveDirections.DIRECTION_UP && entry.coversScreenClear > 50) {
      return true;
    }
  } else if (entry.movement === ContentMoveMovement.MOVEMENT_LEAVE && entry.coversScreenClear > 50) {
    return true;
  }


  return false;
}

function _calculateVisibility(ele) {
  let windowHeight = BrowserContextGetters.currentWindowHeight(),
    docScroll = window.pageYOffset || document.documentElement.scrollTop,
    divPosition = ele.offsetTop,
    divHeight = ele.offsetHeight,
    hiddenBefore = docScroll - divPosition,
    hiddenAfter = (divPosition + divHeight) - (docScroll + windowHeight);

  let divStartInView, divEndInView;

  if ((docScroll > divPosition + divHeight) || (divPosition > docScroll + windowHeight)) {
    return {
      before: hiddenBefore,
      after: hiddenAfter,
      coversScreen: 0,
      percent: 0
    };
  } else {
    let result = 100.0;
    // ich starte hier oder ich ende hier, also
    divStartInView = Math.max(divPosition - docScroll, 0);
    divEndInView = Math.min(
      docScroll + windowHeight,
      divPosition + divHeight
    ) - docScroll;

    if (hiddenBefore > 0) {
      result -= (hiddenBefore * 100) / divHeight;
    }

    if (hiddenAfter > 0) {
      result -= (hiddenAfter * 100) / divHeight;
    }

    return {
      before: divStartInView,
      after: divEndInView,
      coversScreen: Math.round(((divEndInView - divStartInView) / windowHeight) * 10000) / 100,
      percent: Math.round(result * 100) / 100,
    }
  }
}

/**
 *
 * @param {String} toc
 * @param {Element} element
 */
export const removeFromToc = (toc, element) => {
  console.log(toc, element);

}
export const TocStoreGetters = {
  activePage: () => {
    return tocs.activePage
  },
  hasNextTocElement: () => {
    return (tocs.activePage && tocs.activePage.siblings.next);
  }
}

export const TocStoreActions = {
  goToTocElement: (entry, before) => {
    if (!before) {
      entry.element().scrollIntoView({behavior: "smooth"});
    } else {
      window.scrollTo({
        left: 0,
        top: entry.element().offsetTop - before,
        behavior: "smooth"
      });
    }

  },
};

/**
 *
 * @returns {TocEntry[]}
 */
export function getToc() {
  return tocs.entries;
}