<template>
  <a-picture-slide
      background-color="white"
      foreground-color="#4f5d72"
      :auto-adjust="false"
      :center="true"
      src="story/start/bg.jpg"
      :scroll-indicator="true"
  >
    <particles-player
        :particle-file="particleFile"
        :play="play"
    >
    </particles-player>
    <div class="content content-padded-horizontal">
      <h1 class="upper">
        Erklärbare KI
        <small class="upper shadow-inherit">Anforderungen, Anwendungsfälle und Lösungen</small>
      </h1>

      <p style="max-width:600px">
        Für welche Branchen ist erklärbare KI zwingend nötig? Welche Erklärungsstrategien
        sind für die Gesundheitswirtschaft oder die Produktion geeignet? Wie kann die richtige
        Strategie gefunden werden?
      </p>

      <p style="max-width:600px;">
        Diese Seite stellt anschaulich die Ergebnisse aus der Studie „Erklärbare KI –
        Anforderungen, Anwendungsfälle und Lösungen“ dar. Die Studie wurde im Frühjahr
        2021 im Auftrag des Bundesministeriums für Wirtschaft und Klimaschutz (BMWK) im
        Rahmen der Begleitforschung zum Technologieprogramm „Künstliche Intelligenz als
        Treiber für volkswirtschaftlich relevante Ökosysteme“ (KI-Innovationswettbewerb)
        erstellt.
      </p>

      <download-study-button theme="dark"></download-study-button>

    </div>
  </a-picture-slide>
</template>

<script>
import APictureSlide from "@/components/Slide/APictureSlide";
import {BrowserContextGetters} from "@/store/BrowserContext";
import TocAwareSlideMixin from "@/components/TOC/mixin/TocAwareSlide";
import ParticlesPlayer from "@/components/ParticlesPlayer";
import DownloadStudyButton from "@/components/DownloadStudyButton";

export default {
  name: "StartSlide",
  components: {DownloadStudyButton, ParticlesPlayer, APictureSlide},
  mixins: [TocAwareSlideMixin],
  toc: {
    options: {
      hidden: true,
      hideOnToc: true,
      background: "light"
    },
    toc: 'main',
    description: 'Start'
  },
  computed: {
    ...BrowserContextGetters,
    particleFile() {
      return `start/particles-${this.currentBreakpoint}.json`
    },
  },
  data() {
    return {
      play: true
    };
  },
  watch: {
    'currentTocSlide.coversScreen'(val) {
      this.play = val > 5;
    }
  }
}
</script>

<style scoped lang="scss">
.content {

  @media only screen and (max-height: 670px) {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 6rem;
  }

  position: absolute;
  z-index: 2;
  min-height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;

  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    font-weight: 300;
  }
}
</style>