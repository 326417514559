<template>
  <a-slide
      :background-color="backgroundColor"
      :foreground-color="foregroundColor"
      :auto-adjust="autoAdjust"
      :sizing="sizing"
      :center="center"
      :fix="fix"
      :style="{
        backgroundImage: `url(${src})`,
        backgroundAttachment: 'scroll',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundBlendMode: backgroundBlendMode
      }"
      :scroll-indicator="scrollIndicator"
  >

    <slot name="default">
      <template #default>

      </template>
    </slot>
    <!--div class="teaser-container">
      <div
          class="teaser-content"
          :style="{
            textShadow: `0 0 10px ${backgroundColor}`,
            textAlign: 'center'
        }"
      >

      </div>
    </div-->
  </a-slide>
</template>

<script>
import ASlide from "./ASlide";

export default {
  name: "APictureSlide",
  components: {ASlide},
  props: {
    src: {
      type: String,
      required: true
    },
    backgroundColor: {
      description: "Background color",
      type: String,
      default: () => 'inherit'
    },
    backgroundBlendMode: {
      description: "Blend mode",
      type: String,
      default: () => 'none'
    },
    foregroundColor: {
      description: "Dominant foreground color",
      type: String,
      default: () => 'inherit'
    },
    sizing: {
      description: "Height handling; screen == at least 100vh, content == height according slide content",
      validator: (height) => ['screen', 'content'].indexOf(height) !== -1,
      default: 'screen'
    },
    center: {
      description: "Center content - beware, only works with fixed height",
      type: Boolean,
      default: () => false
    },
    autoAdjust: {
      type: Boolean
    },
    fix: {
      type: Boolean,
      default: true
    },
    scrollIndicator: {
      description: "Show the scrollindicator",
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped lang="scss">
.teaser-container {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
</style>