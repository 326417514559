<template>
  <a-slide
      background-color="white"
      foreground-color="#707070"
      :toc-width="400"
      :auto-adjust="true"
      sizing="content"
  >
    <div class="content-padded-horizontal">
      <h2>
        Fazit: Aktuelle Herausforderungen für Erklärbare KI
      </h2>

      <b-row class="text-tile">
        <b-col  cols="12" xl="6">
          <h4>Weiterhin unklare Begriffsdefinitionen</h4>
          <p>
            Eine Vereinheitlichung der Begriffs&shy;definitionen für Erklärbare KI steht von wissenschaftlicher Seite
            noch
            aus.
            Einschlägige Literatur&shy;beiträge wurden erst in den letzten fünf Jahren ver&shy;öffentlicht. Teilweise
            unklar
            formulierte Begrifflichkeiten sowie Artikel, welche die Kategorie von Black-Box-Modellen generell, oder den
            Einsatz von Black-Box-Modellen für kritische Anwendungen ablehnen, deuten jedoch an: Entsprechende Diskurse
            werden mitunter noch sehr unterschiedlich in den wissenschaftlichen Communities geführt.
          </p>
          <h4>Erklärbare KI in vielen Branchen zwingend notwendig</h4>
          <p>
            In der Gesundheits&shy;wirtschaft wird der professionelle Einsatz von KI-Modellen erst durch Erklärbarkeit
            möglich.
            Aber auch in diversen anderen Branchen (Finanz-, Produktions-, Bau&shy;wirtschaft, Prozess&shy;industrie,
            Energiewirtschaft, Dienstleistungs&shy;sektor) erachtet eine Mehrheit der Personen mit entsprechender
            Branchenkenntnis eine gewisse Erklärbarkeit als unverzichtbar.
          </p>
          <h4>Großer Bedarf an Erklärbarer KI für alle Beteiligten</h4>
          <p>
            KI-Erklärbarkeit wird zukünftig auch für weitere Akteur:innen wie interne Prüfer:innen, Management und
            Endkund:innen wichtig. Das unterstreicht den perspektivischen Bedarf für erklärbare KI &mdash; auch abseits von
            regulatorischen Anforderungen.
          </p>
        </b-col>
        <b-col  cols="12" xl="6">
          <h4>„Hybride“ Ansätze werden wichtiger</h4>
          <p>
            Aktuell fehlen Erklärungswerkzeuge, die detaillierte und quantitativ nutzbare Modellerklärungen für
            Black-Box-Modelle, wie neuronale Netze, bereitstellen können. Perspektivisch ist daher auch die Nutzung
            „hybrider“ Ansätze vielversprechend. Diese kombinieren White- mit Black-Box-Komponenten und stellen
            eigenständig Erklärungen bereit.
          </p>
          <h4>Förderung von Best Practices für Erklärbare KI!?</h4>
          <p>
            Die Studie zeigt, dass die Grundlagen&shy;forschung und Herausbildung von Best Practices vorangetrieben
            werden
            sollte. Vor allem die bedeutsamen Anwendungs&shy;felder wurden bisher nur vereinzelt in der
            anwendungs&shy;orientierten
            Forschung adressiert. Effizienz und Anwender&shy;freundlichkeit der Lösungen praktischer Problem&shy;stellungen
            sollten
            noch stärker ins Zentrum der Forschung rücken.
          </p>
          <h4>Weiterentwicklung des Entscheidungsbaumes nötig</h4>
          <p>
            Der Orientierungsbaum berücksichtigt zwar die meistzitierten Ansätze (im Falle ihrer praktischen
            Anwendbarkeit), stellt damit jedoch lediglich eine Moment&shy;aufnahme für den Stand der Technik dar. Im
            Sinne
            der
            Vervollständigung von Best Practices ist es generell empfehlenswert, diese eingeschlagene Richtung
            weiter&shy;zuverfolgen und dabei insbesondere quantitative Vergleiche und die Prüfung der Übertragbarkeit
            der
            Ansätze noch stärker zu berücksichtigen.
          </p>
        </b-col>
      </b-row>
    </div>

  </a-slide>
</template>

<script>
import TocAwareSlideMixin from "@/components/TOC/mixin/TocAwareSlide";
import ASlide from "@/components/Slide/ASlide";
import {BCol, BRow} from 'bootstrap-vue'

export default {
  name: "ConclusionV2",
  components: {ASlide, BCol, BRow},
  mixins: [TocAwareSlideMixin],
  toc: {
    toc: 'main',
    description: "Fazit",
    options: {
      background: "light"
    }
  },
  data() {
    return {
      tocEntry: null,
    }
  },
  methods: {
    showVideo() {
      this.videoVisible = true;
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors";

.content {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  & p.light {
    font-weight: 400;
  }
}

.rounded-box-jail {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;

  .rounded-box {
    & h3 {
      font-size: 25px;
      text-transform: uppercase;
    }

    font-size: 18px;
    text-align: left;
    position: absolute;
    top: -342px;
    left: -500px;
    min-height: 942px;
    background: rgb(83, 176, 151, 0.8);
    color: white;
    padding-top: 390px;
    padding-right: 120px;
    padding-left: 550px;
    padding-bottom: 150px;
  }

  .text-in-box {
    max-width: 380px;
  }
}

.cite-carousel {
  max-width: 700px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  line-height: 200%;

}

.text-tile {

  & h4 {
    text-align: left;
    min-height: 1rem;
    color: $color-pri-3;
    vertical-align: bottom;
    margin-top: 3rem;
  }
  & {


    & p {
      //font-size:95%;
    }

    text-align: left;
    margin-bottom: 2rem;
    //margin-left: 1rem;
    margin-right: 1rem;
  }
}

</style>