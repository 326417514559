import { render, staticRenderFns } from "./YoutubeModal.vue?vue&type=template&id=c08c80b2&scoped=true&"
import script from "./YoutubeModal.vue?vue&type=script&lang=js&"
export * from "./YoutubeModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c08c80b2",
  null
  
)

export default component.exports