<template>
  <div v-on="$listeners">
    <the-icon
              :style="{width: `${size}px`,height: `${size}px`}"
    ></the-icon>
  </div>
</template>

<script>
import TheIcon from "@/assets/icons/overview/k1.svg";

export default {
  name: "AK1Icon",
  components: {
    TheIcon
  },
  props: {
    size: {
      type: Number,
      default: 53
    },
  },
  data() {
    return {
      animation: null
    };
  },
}
</script>

<style lang="scss">
.icon {
  stroke-width: 1;
}
</style>