<template>
  <div class="button" v-on="$listeners" :class="themeClass">
    <div class="caption" :class="hasSymbol?'':'no-sym'">
      <slot name="default"></slot>
    </div>
    <div class="symbol" v-if="hasSymbol">
      <slot name="symbol">

      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AButton",
  computed: {
    hasSymbol() {
      return !!this.$slots.symbol;
    },
    themeClass() {
      return `theme-${this.theme} size-${this.size}`;
    }
  },
  props: {
    size: {
      type: String,
      default: 'large',
      validator: (size) => ['small', 'medium', 'large'].indexOf(size) > -1
    },
    theme: {
      type: String,
      default: "light",
      validator: (theme) => ['light', 'inactive', 'yellow', 'dark', 'active', 'blue'].indexOf(theme) > -1
    }
  }
}

</script>

<style lang="scss">
@import '../../assets/scss/variables';
@import '../../assets/scss/colors';

@mixin theme($dominant, $hover, $useBackground: false) {
  border: solid 1px $dominant;
  background-color: if($useBackground, $dominant, transparent);

  & .caption {
    color: if($useBackground, $hover, $dominant);
  }

  & .icon {
    stroke: $dominant;
  }

  & .icon-stroke {
    stroke: $dominant;
  }

  & .icon-fill {
    fill: $dominant;
  }

  &:hover {
    background-color: $dominant;

    .caption {
      color: $hover;
    }

    .icon {
      stroke: $hover;
    }

    .icon-stroke {
      stroke: $hover;
    }

    .icon-fill {
      fill: $hover;
    }
  }
}

$sizes: ("small": 13, "medium": 15, "large": 18);

@mixin size($baseSize) {

  padding-left: $baseSize * 1.444px;
  padding-right: $baseSize * 1.444px;
  border-radius: $baseSize * 2.5px;

  min-width: $baseSize * 15px;

  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  transition: transform 0.5s ease;


  &:hover {
    transform: scale(1.05);
  }
  & .caption {
    font-size: $baseSize * 1px;
    margin-top: $baseSize * 1px;
    margin-bottom: $baseSize * 1px;
  }

}

.button {
  will-change: transform;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  margin-bottom: 1rem;
  cursor: pointer;
  display: inline-flex;
  flex-flow: row;
  justify-content: space-between;
  background-color: transparent;
  align-items: center;
  box-sizing: border-box;
  transition: background-color 0.5s $default-ease;

  & .caption {
    &.no-sym {
      text-align: center;
      width: 100%;
    }

    display: inline-block;
    line-height: 1.3;
  }

  & .symbol {
    margin-left: 20px;
  }

  & .icon {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 0.6;
  }

  .icon-stroke {
    fill: none;
    stroke-miterlimit: 10;
  }


  &:hover {
    .caption {
      text-shadow: none;
    }

    .icon {
      stroke-miterlimit: 10;
      stroke-width: 0.6;
    }

    .icon-stroke {
      stroke-miterlimit: 10;
    }
  }

  &.theme-light {
    @include theme(white, $color-sec-6);
  }

  &.theme-dark {
    @include theme($color-sec-6, white);
  }

  &.theme-inactive {
    @include theme($color-sec-5, $color-sec-4);
  }

  &.theme-yellow {
    @include theme($color-pri-2, $color-pri-1);
  }

  &.theme-blue {
    @include theme($color-pri-1, white);
  }

  &.theme-active {
    @include theme($color-pri-3, $color-sec-4, true);
  }

  @each $name, $baseSize in $sizes {
    &.size-#{$name} {
      @include size($baseSize);
    }
  }
}
</style>