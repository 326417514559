<template>
  <b-modal
      v-model="videoVisible"
      :centered="true"
      :hide-footer="true"
      :hide-header="true"
      body-class="modal-video"
      size="huge"
  >
    <yt-video-with-consent
        v-if="videoVisible"
        :video-id="videoId"
        :autoplay="true"
        :still="still"
    >
    </yt-video-with-consent>
  </b-modal>
</template>

<script>


import {BModal} from 'bootstrap-vue'
import YtVideoWithConsent from "@/components/YtVideoWithConsent";

export default {
  name: "YoutubeModal",
  components: {
    YtVideoWithConsent,
    BModal
  },
  data() {
    return {
      videoVisible: false
    }
  },
  props: {
    videoId: {
      type: String,
      description: "ID of the video",
      required: true
    },
    still: {
      type: String,
      required: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    value: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    videoVisible: {
      handler(val) {
        this.$emit('input', val);
      }
    },
    value: {
      immediate: true,
      handler(val) {
        this.videoVisible = val;
      }
    }
  }
}
</script>

<style scoped>

</style>