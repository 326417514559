<template>
  <div
      class="vertical-toc-container"
      v-if="currentBreakpoint !== 'small'"
      :class="tVisible?'visible':''"
  >
    <ul :style="{
      color: tocColor
    }">
      <li
          v-for="(ele,k) in entries"
          v-bind:key="k"
          @click="goTo(ele)"
          :style="{textShadow: `0 0 -1px ${tocColor}`}"
      >
        <div
            v-if="currentBreakpoint === 'large'"
            class="description"
            :class="ele.active?'active':''"
        >
          <div style="line-height: 100%;" :inner-html.prop="description(ele)">
          </div>
        </div>
        <div
            class="indicator"
            :style="{borderColor: tocColor}"
        >
          <div
              class="indicator-inner"
              :class="ele.active?'active':''"
              :style="ele.active?{backgroundColor: tocColor}:{}"
          >
            &nbsp;
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {BrowserContextGetters} from '@/store/BrowserContext'
import {nl2br} from "@/utils/TextUtils";

export default {
  name: "VerticalTocMenu",
  props: {
    entries: {
      type: Array,
      description: "Menu entries"
    },
    jump: {
      type: Function,
      description: "Functions to jump to target"
    },
    visible: {
      type: Boolean,
      default: true
    },
    activePage: {
      type: Object
    }
  },
  computed: {
    ...BrowserContextGetters,
    tocColor() {
      if (this.currentBackground === 'light') {
        return 'var(--color-for-white)';
      } else {
        return 'var(--color-for-black)';
      }
    }
  },
  data() {
    return {
      currentBackground: "dark",
      tVisible: false
    }
  },
  methods: {
    goTo(entry) {
      this.jump(entry);
    },
    description(entry) {
      return nl2br(entry.description);
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        this.tVisible = val;
      }
    },
    activePage: {
      immediate: true,
      handler(val) {
        this.tVisible = val && !val.options.hideOnToc;
        if (val) {
          this.currentBackground = val.options.background;
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables';

.modal-open .vertical-toc-container {
  padding-right: var(--scrollbar-width);
}

.vertical-toc-container {
  transition: visibility 1s $default-ease, opacity 1s $default-ease;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  text-align: right;
  top: 20vh;
  bottom: 20vh;
  background-color: transparent;
  z-index: 10;
  font-size: 16px;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  .breakpoint-large & {
    right: 3rem;
  }

  .breakpoint-medium & {
    right: 20px;
  }

  ul {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: flex-end;
    list-style: none;
    transition: color 0.2s $default-ease;

    li {
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      justify-content: right;
      align-items: center;
      height: 60px;

      .indicator {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        border-radius: 10px;
        border: solid 1px;

        .indicator-inner {
          width: 0;
          height: 0;
          transition: width 0.2s $default-ease, height 0.2s $default-ease;
          border-radius: 6px;
          margin: 0;

          &.active {
            width: 12px;
            height: 12px;
          }
        }
      }

      .description {
        margin-right: 20px;
        transition: font-size 0.4s $default-ease, color 0.4s $default-ease;
        max-width: 400px;

        &.active {
          font-size: 32px;
          font-weight: 700;
        }
      }
    }
  }
}
</style>