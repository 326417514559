<template>
  <div class="tile-container">
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  name: "ResponsiveTileContainer"
}
</script>

<style scoped lang="scss">
.tile-container {
  .breakpoint-medium &, .breakpoint-large & {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: space-evenly;
  }
}
</style>