<template>
  <div
      ref="markdownCanvas"
      class="markdown-story bg-is-light"
  >

  </div>
</template>

<script>
import axios from 'axios'
import marked from 'marked';

export default {
  name: "MarkdownStory",
  props: {
    file: {
      type: String
    }
  },
  methods: {
    loadFile(file) {
      axios.get(file).then((response) => {
        this.$refs.markdownCanvas.innerHTML = marked(response.data, {
          baseUrl: "story/"
        });
      }).catch((error) => {
        this.$refs.markdownCanvas.innerHTML = `Kann ${file} nicht laden. Grund: ${error.response.statusText} (${error.response.status})`;
      });
    }
  },
  watch: {
    file: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadFile(val);
        } else {
          this.$refs.markdownCanvas.innerHTML = "";
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.markdown-story {
  h1, h2, h3, h4, h5, p {
    color: black !important;
  }
}
</style>