<template>
  <div class="video-max">
    <b-aspect :aspect="aspectRatio">
      <iframe
          allowfullscreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          :style="backgroundStillStyle"
          v-if="hasVideoAccepted && this.getVideoUrl !== ''"
          width="100%"
          height="100%"
          :src="getVideoUrl"
      >
      </iframe>
      <div
          class="consent-container"
          :style="backgroundStillStyle"
          v-if="!hasVideoAccepted"
      >
        <div class="consent-container-text">
          Zum Aktivieren des Videos müssen Sie unten auf den Link klicken. Wir möchten Sie darauf
          hinweisen, dass nach der Aktivierung Daten an Youtube übermittelt werden können. Mit der Aktivierung werden
          alle Videos auf der Seite automatisch geladen.
          <br/>

          <a-button @click="signalConsent" theme="light" size="small" class="mt-3">
            Abspielen
            <template #symbol>
              <a-play-icon :size="28"></a-play-icon>
            </template>
          </a-button>
        </div>
      </div>
    </b-aspect>
  </div>
</template>

<script>
import {VideoConsentGetter, VideoConsentMutator} from "@/store/VideoConsent";
import AButton from "@/components/Button/AButton";
import APlayIcon from "@/components/Icons/APlayIcon";
import {BAspect} from 'bootstrap-vue';

// https://www.youtube.com/watch?v=RnqAXuLZlaE
export default {
  name: "YtVideoWithConsent",
  components: {APlayIcon, AButton, BAspect},
  props: {
    videoId: {
      type: String,
      required: true
    },
    still: {
      type: String,
      required: false
    },
    autoplay: {
      default: true,
      type: Boolean
    },
    aspectRatio: {
      default: "16:9",
      description: "Take a look at the supported ARs from bootstrap-vue/BAspect"
    }
  },
  computed: {
    hasVideoAccepted() {
      return VideoConsentGetter.hasAccepted();
    },
    backgroundStillStyle() {
      if (!this.still) {
        return {};
      } else {
        return {
          background: `url('${this.still}') no-repeat center center`,
          backgroundSize: "auto 100%"
        }
      }
    },
    getVideoUrl() {
      if (!this.videoId) {
        return "";
      }

      return `https://www.youtube-nocookie.com/embed/${this.videoId}?fs=1&rel=0&modestbranding=1&iv_load_policy=3&enablejsapi=0&controls=2&autoplay=${this.autoplay ? 1 : 0}`;
    }
  },
  methods: {
    signalConsent() {
      VideoConsentMutator.updateConsent(true);
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/colors';

iframe {
  border: 0;
  background-size: auto 100%;
}

.video-max {
  width: 100%;
  height: 100%;
  position: relative;
}

.consent-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.consent-container-text {
  top: 50%;
  transform: translate(0, -50%);
  font-size: 12px;
  color: white;
  position: absolute;
  width: 100%;
  text-align: center;
  background-color: rgba($color-pri-3, .8);
  padding: 1rem 3rem;
}

</style>