<template>
  <the-icon style="width:30px" ref="theicon"
            v-on="$listeners">

  </the-icon>
</template>

<script>
import TheIcon from "@/assets/icons/download.svg";
import gsap from "gsap";

export default {
  name: "ADownloadIcon",
  components: {
    TheIcon
  },
  props: {
    animate: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.animate) {
        let eleWheel = this.$refs.theicon.querySelectorAll('.arrow');
        let tl = gsap.timeline({repeat: -1});
        tl.to(eleWheel, {duration: 0.5, y: "+=2", repeat: 5, yoyo: true, delay: 2});
      }
    });
  }
}
</script>

<style scoped lang="scss">

</style>