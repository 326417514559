<template>
  <a href="https://www.digitale-
technologien.de/DT/Redaktion/DE/Downloads/Publikation/KI-Inno/2021/Studie_Erklaerbare_KI.html"
     onclick="window._paq.push(['trackEvent', 'Download', 'Study download']);"
     target="_blank">
    <a-button style="margin-top: 2rem;" :theme="theme">
      Download der Studie
      <template #symbol>
        <a-download-icon></a-download-icon>
      </template>
    </a-button>
  </a>
</template>

<script>
import AButton from "@/components/Button/AButton";
import ADownloadIcon from "@/components/Icons/ADownloadIcon";

export default {
  name: "DownloadStudyButton",
  components: {ADownloadIcon, AButton},
  props: {
    theme: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  &:hover,
  &:visited,
  &:active {
    text-decoration: none;
  }

  text-decoration: none;
}
</style>