<template>
  <div class="the-video-container" ref="theVideoContainer">
    <video
        autoplay
        muted
        loop
        class="the-video"
        ref="thevideo"
        @loadeddata="onResize"
    >
      <source type="video/mp4" :src="videoSource"/>
    </video>
    <div
        v-if="overlayImage"
        class="overlay-image" :style="{backgroundImage: `url(${overlayImage}`}"
    ></div>
  </div>
</template>

<script>
export default {
  name: "BackgroundVideo",
  props: {
    videoSource: {
      type: String
    },
    overlayImage: {
      type: String
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.src = this.video;
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    nextScene(newVal) {
      this.changeScene(newVal)
    }
  },
  methods: {
    /**
     * Resizes the video according to the current resolution to make sure it always covers the entire background
     * Part of the video would be cropped, but AR will be maintained.
     */
    onResize() {
      const videoElement = this.getVideoElement();
      const vw = Math.max(videoElement.videoWidth, 0);
      const vh = Math.max(videoElement.videoHeight, 0);

      if (vw === 0 || vh === 0) {
        return;
      }
      const videoAR = vw / vh;

      const dw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const dh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      const displayAR = dw / dh;

      let targetHeight = 0;
      if (displayAR >= videoAR) {
        // keep display width
        targetHeight = dw / videoAR;
      } else {
        // keep display height
        targetHeight = dh;
      }
      //videoElement.style.width = `${targetWidth}px`;
      videoElement.style.minHeight = `${targetHeight}px`;

    },
    changeScene({file}) {
      const video = this.getVideoElement();
      if (this.video === file && this.video !== "") {
        return;
      }
      this.video = file;
      video.src = file;
      video.load();
    },

    getVideoElement() {
      return this.$refs.thevideo;
    },
  }
}
</script>

<style scoped lang="scss">
.the-video-container {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-color: black;

  .the-video {
    object-position: 70% 50%;
  }

  .overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-repeat: repeat;
    background-color: rgb(222, 237,255);
    background-blend-mode: multiply;
    opacity: 0.3;
  }
}

</style>