var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-slide',{style:({
      backgroundImage: ("url(" + _vm.src + ")"),
      backgroundAttachment: 'scroll',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundBlendMode: _vm.backgroundBlendMode
    }),attrs:{"background-color":_vm.backgroundColor,"foreground-color":_vm.foregroundColor,"auto-adjust":_vm.autoAdjust,"sizing":_vm.sizing,"center":_vm.center,"fix":_vm.fix,"scroll-indicator":_vm.scrollIndicator}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }