<template>
  <div class="use-case-container">
    <b-row>
      <b-col cols="12" col-xl="6">
        <div class="logo-container">
          <div class="symbol">
            <lottie-icon
                style="vertical-align: middle"
                icon="mikroskop"
                animate="hover"
                size="medium"
            ></lottie-icon>
          </div>
          <div class="heading">
            <h3 class="upper">
              Use case
              <small>KI-gestützte Bildanalyse histologischer Gewebeschnitte</small>
            </h3>
          </div>
        </div>


      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6">
        <table class="use-case-table mb-5">
          <tbody>
          <tr>
            <th>Typ</th>
            <td>Anomalieerkennung (Entscheidungsunterstützung)</td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <th>Kritikalität</th>
            <td>Sehr hoch (Medizinprodukt)</td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <th>Datentypen</th>
            <td>Bilddaten (2D, 3D), digitalisierte Gewebeschnitte, hohe Auflösung</td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <th>Typische KI-Modelle</th>
            <td>Neuronale Netze (CNNs, GANs), (Erklärbarkeitsdefizite bzw. Black-Box-Modelle)</td>
          </tr>
          </tbody>

          <tbody class="separator-top">
          <tr>
            <th rowspan="3">
              (Wichtigste) Zielgruppen<br/>
              <small>und jeweils übergeordnete Ziele für die Nutzung erklärbarer KI</small>
            </th>
            <td>
              <div class="cell-head">
                Domänenexpert:innen<small> (medizinisches Personal):</small>
              </div>
              Kausalitätsbeziehungen finden
            </td>
          </tr>
          <tr>
            <td class="separator-top">
              <div class="cell-head">
                Entwickler:innen<small>:</small>
              </div>
              Konfidenz (Robustheit, Stabilität) bestimmen, Fairness testen (Databias finden)
            </td>
          </tr>
          <tr>
            <td class="separator-top">
              <div class="cell-head">
                ZULASSENDE BEHÖRDEN<small> (Benannte Stellen):</small>
              </div>
              Zulassungsanforderungen prüfen
            </td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <th rowspan="3">Konkrete Anforderungen<br/>
              an Erklärbarkeit
            </th>
            <td>
              <div class="cell-head">
                Domänenexpert:innen <small>und</small> Entwickler:innen
              </div>
              Bewertung der Güte von Einzel- bzw. „lokalen“ Entscheidungen;
            </td>
          </tr>
          <tr>
            <td class="separator-top">
              <div class="cell-head">
                Entwickler:innen<small>:</small>
              </div>
              Bewertung der Güte von Modellen und Bias in den Trainingsdaten aufdecken (über lokale Erklärungen);
            </td>
          </tr>
          <tr>
            <td class="separator-top">
              <div class="cell-head">
                ZULASSENDE BEHÖRDEN<small> (Benannte Stellen):</small>
              </div>
              Reduzierung der Komplexität, „Nachvollziehbarkeit“ (konkrete Anforderun-gen werden derzeit diskutiert)
            </td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <th>Geeignete Erklärungsstrategien</th>
            <td>
              Entscheidungserklärungen (Post hoc), z. B. LRP, LIME
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
      <b-col cols="12" lg="6" class="use-case-text">
        <markdown-it-vue-light
            ref="markdown"
            :content="content"
        >

        </markdown-it-vue-light>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BRow, BCol} from 'bootstrap-vue';
import MarkdownItVueLight from '@/components/MarkdownItVueLighter/markdown-it-vue-light'
import LottieIcon from "@/components/Lottie/LottieIcon";

require('markdown-it-footnote');

import axios from "axios";

export default {
  name: "UseCase1",
  components: {
    BRow, BCol,
    MarkdownItVueLight,
    LottieIcon
  },
  data() {
    return {
      content: ""
    }
  },
  mounted() {
    axios.get('story/usecase/usecase1.md').then(({data}) => {
      return this.content = data;
    });
  },
}
</script>

<style scoped lang="scss">

</style>