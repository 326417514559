import Vue from 'vue';

const VideoConsent = Vue.observable({
  accepted: false
});

export const VideoConsentMutator = {
  updateConsent(val) {
    VideoConsent.accepted = val;
  }
};

export const VideoConsentGetter = {
  hasAccepted() {
    return VideoConsent.accepted;
  }
};
