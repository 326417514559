import Vue from 'vue';
import BrowserConfig from "@/config/BrowserConfig";

const BrowserContextState = Vue.observable({
    breakpoint: 'small',
    windowHeight: 0,
    windowWidth: 0,
    documentHeight: 0,
    hasBeenScrolled: false
});

export const BrowserContextGetters = {
    currentBreakpoint: () => {
        return BrowserContextState.breakpoint;
    },
    currentWindowHeight: () => {
        return BrowserContextState.windowHeight;
    },
    currentWindowWidth: () => {
        return BrowserContextState.windowWidth;
    },
    currentDocumentHeight: () => {
        return BrowserContextState.documentHeight;
    },
    currentMenuHeight: () => {
        return BrowserConfig.menuHeight[BrowserContextState.breakpoint];
    },
    hasBeenScrolled: () => {
        return BrowserContextState.hasBeenScrolled
    },
    timeForBurger: () => {
        return BrowserContextState.breakpoint !== "large";
    }
};

export const BrowserContextMutations = {
    setCurrentBreakpoint(breakpoint) {
        BrowserContextState.breakpoint = breakpoint;
    },
    setCurrentWindowHeight(height) {
        BrowserContextState.windowHeight = height;
    },
    setCurrentWindowWidth(width) {
        BrowserContextState.windowWidth = width;
    },
    setCurrentDocumentHeight(height) {
        BrowserContextState.documentHeight = height;
    },
    setHasBeenScrolled(val) {
        BrowserContextState.hasBeenScrolled = val;
    }
};

export default BrowserContextState;