<template>
  <nav role="navigation" :class="`${visible?'nav-visible':''} ${menuOpen?'open':''}`">
    <div class="mobile-navigation-container">
      <div class="nav-element brand">
        <slot name="default">
          {{ pageTitle }}
        </slot>
      </div>
      <div class="nav-element">
        <div class="menu-icon" @click="toggleMobileMenu">
          <span class="navicon" :class="`${menuOpen?'open':''}`"></span>
        </div>
      </div>
    </div>
    <div class="navigation-container" v-if="menuOpen">
      <ul>
        <li v-for="(entry,i) in entries" v-bind:key="i"
            class="entry"
            :class="mobileEntryTheme(entry)"
            :progress="percent(i)"
            @mouseenter="hovered=entry"
            @mouseleave="hovered=null"
            @click="closeAndGoTo(entry)"
        >
          {{ description(entry) }}
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>

import {nl2none} from "@/utils/TextUtils";

export default {
  name: "MobileMenu",
  components: {},
  props: {
    entries: {
      type: Array,
      description: "Menu entries"
    },
    jump: {
      type: Function,
      description: "Functions to jump to target"
    },
    visible: {
      type: Boolean,
      default: true
    },
    activePage: {
      type: Object
    }
  },
  data() {
    return {
      menuOpen: false,
      hovered: null
    }
  },
  computed: {
    pageTitle() {
      if (this.activePage && this.activePage.description) {
        return this.description(this.activePage);
      } else {
        return "";
      }
    }
  },
  methods: {
    description(entry) {
      return nl2none(entry.description);
    },
    mobileEntryTheme(entry) {
      if (this.hovered === entry) {
        return 'entry-hovered';
      }

      return entry.active ? 'entry-active' : '';
    },
    toggleMobileMenu() {
      if (this.menuOpen) {
        this.closeMobileMenu();
      } else {
        this.openMobileMenu();
      }
    },
    openMobileMenu() {
      this.menuOpen = true;
      this.hovered = false;
    },
    closeMobileMenu() {
      this.menuOpen = false;
      this.hovered = false;
    },
    percent(i) {
      return ((i + 1) / this.entries.length) * 100;
    },
    closeAndGoTo(entry) {
      this.closeMobileMenu();
      setTimeout(() => {
        this.jump(entry);
      }, 150);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors";
@import "../../assets/scss/variables";

nav {
  transition: opacity 1s $default-ease, height .3s $default-ease, max-height .3s $default-ease;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: var(--menu-height);
  max-height: var(--menu-height);
  opacity: 0;
  z-index: 20;
  background-color: #F2F0EF;
  color: $theme-color-menu;
  box-shadow: 0 3px 3px #00000039;
  overflow: hidden;

  &.nav-visible {
    visibility: visible;
    opacity: 1;
  }

  .menu-icon {
    cursor: pointer;
    display: block;
    position: relative;
    user-select: none;
    padding: 28px 0 28px 20px;

    .navicon {
      &:before,
      &:after {
        background: $theme-color-menu;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: background .3s $default-ease, transform .3s $default-ease;
        width: 100%;
      }

      &:before {
        top: 5px;
      }

      &:after {
        top: -5px;
      }

      &.open {
        background: transparent;

        &:before {
          transform: rotate(-45deg);
          top: 0;
        }

        &:after {
          transform: rotate(45deg);
          top: 0;
        }
      }

      background: $theme-color-menu;
      display: block;
      height: 2px;
      position: relative;
      transition: background .3s $default-ease, transform .3s $default-ease;
      width: 18px;
    }
  }

  .mobile-navigation-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--menu-height);
    margin-left: auto;
    margin-right: auto;
    max-width: $maxContentWidth;
    @include keepout-padded-horizontal();

    .nav-element {
      display: inline-flex;

      &.brand {
        text-overflow: ellipsis;
      }
    }

  }

  &.open {
    height: auto;
    max-height: 1500px;

    .menu-icon {
      //padding-right: var(--scrollbar-width);
    }

    .mobile-navigation-container {
      box-shadow: 0 3px 3px #00000039;
    }

    .navigation-container {
      //overflow-y: scroll;
    }
  }

  .navigation-container {
    border-top: dotted 1px $color-pri-3;

    ul {
      list-style: none;

      & > li {
        padding-top: 10px;
        padding-left: 10px;
      }
    }

    .entry {
      margin-bottom: $keep-out-area-small;
      cursor: pointer;

      &.entry-hovered {
        color: $color-sec-1;
      }

      &.entry-active {
        color: $color-pri-3;
      }
    }
  }
}

.modal-open .menu-icon {
  padding-right: var(--scrollbar-width);
}
</style>