<template>
  <div class="tile-wrapper">
    <slot name="default">

    </slot>
  </div>
</template>

<script>
export default {
  name: "TileContainer"
}
</script>

<style scoped lang="scss">

.tile-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
</style>