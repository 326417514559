<template>
  <a-slide
      :src="require('@/assets/img/the-study/background.png')"
      background-color="#aaaaaa"
      foreground-color="white"
      :auto-adjust="true"
      :fix="false"
      :padded="false"
  >
    <template #heading>
      Erklärung zur Publikation
    </template>
    <template #subtext>
      Wieso, Weshalb, Warum?
    </template>

    <background-video
        video-source="story/study/bg-video-lene.mp4"
        overlay-image="story/study/overlay.png"
        :parent="$el"
    >
    </background-video>
    <div class="flex-c">
      <div class="content content-padded shadow-theme-light">
        <h3 class="light headline">
          Studienautorin Lene Ganschow erklärt die Hintergründe der Studie
        </h3>

        <div>
          <a-button style="margin-top: 1rem;" @click="showVideo()">
            Interview ansehen
            <template #symbol>
              <a-play-icon :size="41"></a-play-icon>
            </template>
          </a-button>
        </div>

        <youtube-modal
            video-id="U9ig2ONq79k"
            still="story/study/still-lene.png"
            v-model="videoVisible"
        >
        </youtube-modal>
      </div>

      <div class="to-clip content-padded-horizontal content-padded-bottom">
        <div class="text-box">
          <h3 class="light">Die Studie „Erklärbare KI“ kurz erklärt </h3>
          <p>
            Zahlreiche Produkte und Dienstleistungen in allen Lebensbereichen machen bereits die enormen Potenziale
            Künstlicher Intelligenz deutlich. Zentraler Erfolgsfaktor ist dabei die Erklärbarkeit der Entscheidungen,
            die mit Unterstützung von KI-Anwendungen getroffen werden. Erklärbarkeit stärkt die Akzeptanz bei
            Nutzer:innen und
            ist im Rahmen von Zulassungs- und Zertifizierungsverfahren sowie zur Erfüllung von Transparenzpflichten
            zentrale Voraussetzung. Die vom BMWK beauftragte Studie befasst sich mit dem aktuellen Stand von
            erklärbarer KI und gibt KI-Entwickler:innen und Anwender:innen anhand praxisnaher Use Cases einen
            praktischen Überblick zu potenziellen Erklärungsstrategien.
          </p>
        </div>
      </div>
    </div>
  </a-slide>
</template>

<script>
import TocAwareSlideMixin from "@/components/TOC/mixin/TocAwareSlide";
import AButton from "@/components/Button/AButton";
import APlayIcon from "@/components/Icons/APlayIcon";
import YoutubeModal from "@/components/YoutubeModal";
import ASlide from "@/components/Slide/ASlide";
import BackgroundVideo from "@/components/BackgroundVideo";

export default {
  name: "TheStudy",
  components: {BackgroundVideo, ASlide, YoutubeModal, APlayIcon, AButton},
  mixins: [TocAwareSlideMixin],
  toc: {
    toc: 'main',
    description: "Studie kurz erklärt",
    subtitle: '',
    options: {
      background: "light"
    }
  },
  data() {
    return {
      tocEntry: null,
      borderRadius: 571,
      dimension: 1042,
      videoVisible: false
    };
  },
  mounted() {
    //window.addEventListener('resize', this.fixCircle);
    //this.fixCircle();
  },
  beforeDestroy() {
    //window.removeEventListener('resize', this.fixCircle);
  },
  methods: {
    showVideo() {
      this.videoVisible = true;
    },
    fixCircle() {
      this.dimension = (Math.max(this.$refs.rounded.clientWidth, this.$refs.rounded.clientHeight));
      this.borderRadius = this.dimension / 2;
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/colors';

.flex-c {
  overflow: hidden;
}
.content {
  overflow: hidden;

  .breakpoint-large & {
    position: absolute;
  }

  .breakpoint-medium &,
  .breakpoint-small & {
    position: relative;
  }

  text-align: center;
  z-index: 21;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  & p.light {
    font-weight: 300;
  }
}

.headline {
  .breakpoint-small & {
    max-width: 330px;
  }
  max-width: 430px;
}

.flex-c {
  .breakpoint-medium &,
  .breakpoint-small & {
    display: flex;
    flex-flow:column;
    justify-content: space-between;
  }
}

.rounded-box-jail {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  overflow: hidden;
}

.to-clip {

  .breakpoint-large & {
    position: absolute;
    padding-top: 8rem;
  }

  .breakpoint-medium &,
  .breakpoint-small & {
    position: relative;
    padding-top: 10rem;
  }

  position: absolute;
  bottom: 0;

  & h3 {
    font-size: 25px;
    text-transform: uppercase;
    width: 70%;
  }

  & .text-box {
    max-width: 350px;
  }

  font-size: 18px;
  text-align: left;
  background: rgb(83, 176, 151, 0.8);
  color: white;
  width: 590px;
  clip-path: circle(450px at 15% 65%);
}

</style>