<template>
      <the-icon
          v-on="$listeners"
          ref="theicon"
      ></the-icon>
</template>

<script>
import TheIcon from "@/assets/icons/mouse-new.svg";
import gsap from 'gsap';

export default {
  name: "AMouseIcon",
  components: {
    TheIcon
  },
  props: {
    target: {},
    theme: {
      type: String,
      validator: (theme) => ["dark", "light"].indexOf(theme) > -1,
      default: "light",
    },
    animate: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.animate) {
        let eleWheel = this.$refs.theicon.querySelectorAll('.mouse-wheel');
        let tl = gsap.timeline({repeat: -1});
        tl.to(eleWheel, {duration: 0.5, y: "+=15", repeat: 5, yoyo: true, delay: 2});
      }
    });

  }
}
</script>

<style scoped lang="scss">

</style>