<template>
  <div>
    <div v-if="story.length>1" class="detail-chooser">
      <a-button
          size="small"
          class="mr-2"
          :theme="articleFile === s.article?'active':'inactive'"
          v-for="s in story" v-bind:key="s.article"
          @click="loadArticle(s.article)"
      >
        {{ s.title }}
      </a-button>
    </div>

    <MarkdownStory :file="articleFile" v-if="articleFile">

    </MarkdownStory>
  </div>
</template>

<script>
import MarkdownStory from "@/components/MarkdownStory";
import AButton from "@/components/Button/AButton";

export default {
  name: "MultipleMarkdownStories",
  components: {AButton, MarkdownStory},
  props: {
    story: {
      required: true
    },
    article: {
      type: String
    }
  },
  data() {
    return {
      articleFile: null
    }
  },
  methods: {
    loadArticle(article) {
      this.articleFile = article;
    }
  },
  watch: {
    story: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.articleFile = val[0].article;
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/colors';

.detail-chooser {
  border-bottom: dotted 1px $border-table-separator;
  margin-bottom: 1rem;
  margin-left: 0;
  margin-right: 0;
  padding-left: 15px;
  padding-right:15px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
</style>