<template>
  <a-slide
      background-color="#f5f3f2"
      foreground-color="#4f5d72"
      :auto-adjust="true"
      :toc-width="400"
      :center="true"
      sizing="content"
      padded="true"
  >
    <div class="content-padded-horizontal">
      <h2>
        Der KI-Innovations&shy;wettbewerb
      </h2>
      <b-row style="text-align: justify">
        <b-col cols="12" xl="6">
          <h4>Der KI-Innovationswettbewerb</h4>
          <p>
            Der KI-Innovationswettbewerb ist ein wichtiger Baustein der <a target="_blank"
                                                                           href="https://www.ki-strategie-deutschland.de/home.html">nationalen
            KI-Strategie</a>.
            Damit fördert <a target="_blank" href="https://www.bmwk.de/">das Bundesministerium für Wirtschaft und
            Klimaschutz
            (BMWK)</a> ausgewählte Projekte,
            die KI-basierte Plattformkonzepte entwickeln. Die Projekte sollen als Leuchttürme Impulse für den KI-Einsatz
            unter anderem in folgenden wichtigen Sektoren der deutschen Wirtschaft setzen: Bauwirtschaft, Finanzen,
            Gesundheit, Handel, Landwirtschaft, Mobilität, Smart Living, Produktion und Quantencomputing.
          </p>
          <p>
            Die Projekte werden durch eine wissenschaftliche Begleitforschung bei ihrer Entwicklung, und
            dem Transfer ihrer Ergebnisse unterstützt. Daneben gehört die Bearbeitung übergreifender Querschnittsthemen
            zu
            den Aufgaben der Begleitforschung. Adressiert werden unter anderem Fragen zum vertrauenswürdigen
            Data-Sharing, zur Erklärbarkeit von KI-Anwendungen sowie der Akzeptanz und dem Vertrauen in KI-basierte
            Lösungen. Die Themen werden gemeinsam mit den Projekten in Fachgruppen bearbeitet und Publikationen wie die
            vorliegende Studie erarbeitet.
          </p>
          <p>
            <a target="_blank" href="http://www.ki-innovationen.de">www.ki-innovationen.de</a>
          </p>
        </b-col>
        <b-col cols="12" xl="6" class="mt-5 mt-xl-0">
          <h4>Zur Studie "Erklärbare KI"</h4>
          <p>
            Die Studie „Erklärbare KI -Anforderungen, Anwendungsfälle und Lösungen“ wurde von der Begleitforschung zum
            KI-Innovations&shy;wettbewerb im Auftrag des Bundes&shy;ministeriums für Wirtschaft und Klimaschutz im April
            2021
            veröffentlicht.
          </p>
          <p>
            Sie basiert auf einer von Sommer bis Herbst 2020 durchgeführten Online-Befragung, an der sich 209
            Vertreter:innen aus Unternehmen mit KI-Bezug sowie wissenschaftlichen Einrichtungen beteiligt haben.
            Darunter
            waren KI-Entwickler:innen (etwa 75 Prozent) und KI-Anwender:innen (etwa 25 Prozent der befragten Personen).
            Durch Tiefeninterviews mit ausgewiesenen Expert:innen wurden vier Use-Cases genauer analysiert.
          </p>
          <!-- https://www.digitale-technologien.de/DT/Redaktion/DE/Downloads/Publikation/KI-Inno/2021/Studie_Erklaerbare_KI.html   -->
          <p style="text-align: center">
            <download-study-button theme="dark"></download-study-button>
          </p>
        </b-col>
      </b-row>
    </div>
  </a-slide>
</template>

<script>
import TocAwareSlideMixin from "@/components/TOC/mixin/TocAwareSlide";
import {BCol, BRow} from 'bootstrap-vue';
import ASlide from "@/components/Slide/ASlide";
import DownloadStudyButton from "@/components/DownloadStudyButton";

export default {
  name: "InnovationContest",
  components: {DownloadStudyButton, ASlide, BCol, BRow},
  mixins: [TocAwareSlideMixin],
  toc: {
    toc: 'main',
    description: "KI-Innovations-\nwettbewerb",
    options: {
      background: "black"
    }
  },
  data() {
    return {
      tocEntry: null
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors";
.breakpoint-small {
  .col {
    margin-bottom: 3rem;
  }
}

h4 {
  color: $color-pri-3;
}


</style>