<template>
  <div v-on="$listeners">
      <the-icon ref="theicon"
                :style="{width: `${size}px`,height: `${size}px`}"
      ></the-icon>
  </div>
</template>

<script>
import TheIcon from "@/assets/icons/overview/k6.svg";

export default {
  name: "AK6Icon",
  components: {
    TheIcon
  },
  props: {
    size: {
      type: Number,
      default: 53
    }
  }
}
</script>

<style scoped lang="scss">
.icon {
  stroke-width: 1;
}
</style>