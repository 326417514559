<template>
  <a-slide
      background-color="#fff"
      foreground-color="#707070"
      :auto-adjust="true"
      :toc-width="400"
      :padded="true"
      sizing="content"
      id="lottie-container"
  >
    <div class="content-padded-horizontal">
      <h2>
        Interaktiver Entscheidungsbaum
        <small>
          Orientierungshilfe zur Auswahl von KI-Erklärungsstrategien
        </small>
      </h2>
    </div>
    <div class="decision-explainer content-padded-horizontal">
      <div class="b-bottom">
        <h4 class="light">
          Der Entscheidungsbaum kurz erklärt
        </h4>

        <p>
          Der Entscheidungsbaum bietet Orientierung bei der Auswahl von Erklärungsstrategien und -werkzeugen.
          Bitte beachten Sie, dass nur eine Auswahl bereits etablierter Tools berücksichtigt wurde und die
          Angaben auf Erfahrungen konkreter Anwendungsfälle beruhen.
        </p>
        <div style="text-align: center">
          <a-button @click="showVideo()" theme="blue" style="justify-self: flex-start">
            Erklärung ansehen
            <template #symbol>
              <a-play-icon :size="33"></a-play-icon>
            </template>
          </a-button>
        </div>
        <p>
          Der Pfad des Baumes, für den Sie sich entscheiden, wird durch Herunterscrollen nach und nach sichtbar. Unten
          angekommen, erscheint auch der zweite Pfad vollständig.
        </p>
      </div>

    </div>

    <div style="text-align: center">
      <a-button
          theme="blue"
          style="margin-top: 2rem;"
          v-show="this.deciderController"
          @click="playExplainer">
        Beginnen
        <template #symbol>
          <a-mouse-icon theme="light" style="max-width: 40px; max-height: 40px;">

          </a-mouse-icon>
        </template>
      </a-button>
    </div>

    <youtube-modal
        video-id="RNAENf1n-wc"
        v-model="videoVisible"
        still="story/decisiontree/still-lene.jpg"
    >
    </youtube-modal>
    <div
        class="decision-animation"
    >
      <lottie-animation
          class="decision-selector"
          :auto-play="false"
          :loop="false"
          :speed="overallSpeed"
          path="story/decisiontree/lottie/audience-selector.json"
          @AnimControl="setDeciderController"
      >
      </lottie-animation>

      <div ref="lottieContainer">
        <lottie-animation v-if="currentStory !== null"

                          :auto-play="false"
                          :loop="false"
                          :speed="overallSpeed"
                          :path="currentStory"
                          @AnimControl="setAnimController"
                          @markers="updateMarkers"
        >
        </lottie-animation>
      </div>
    </div>

    <b-modal
        v-model="visible"
        :scrollable="true"
        :centered="true"
        :hide-footer="true"
        :title="title"
        header-class="modal-study-header"
        size="huge-fixedheight"
    >
      <MultipleMarkdownStories :story="stories" v-if="stories">
      </MultipleMarkdownStories>
    </b-modal>

    <div class="decision-explainer content-padded-horizontal" v-if="deciderPlaying" :style="{opacity: deciderPlaying?1:0}">
      <div class=" b-top">
        <p class="pt-4">
          Weitere Informationen zu den Möglichkeiten der einzelnen XAI-Tools bekommen Sie mit einem Klick auf den
          jeweiligen Endpunkt im Entscheidungsbaum.
        </p>
        <a href="story/decisiontree/KI_XAI_Entscheidungsbaum.pdf" target="_blank" v-show="reachedEnd"
           aria-label="Entscheidungsbaum als PDF">
          <a-button theme="dark" size="small"
          >
            Entscheidungsbaum
            <template #symbol>
              <a-download-icon></a-download-icon>
            </template>
          </a-button>
        </a>
      </div>
    </div>

  </a-slide>
</template>

<script>
import TocAwareSlideMixin from "@/components/TOC/mixin/TocAwareSlide";
import LottieAnimation from "@/components/Lottie/LottieAnimation";
import {BModal} from 'bootstrap-vue';
import LinkedList from "@/utils/LinkedList";

// used for documentation
import {AnimationItem} from 'lottie-web' // eslint-disable-line
import AButton from "@/components/Button/AButton";
import AMouseIcon from "@/components/Icons/AMouseIcon";
import MultipleMarkdownStories from "@/components/MultipleMarkdownStories";
import ADownloadIcon from "@/components/Icons/ADownloadIcon";
import YoutubeModal from "@/components/YoutubeModal";
import APlayIcon from "@/components/Icons/APlayIcon";
import ASlide from "@/components/Slide/ASlide";


/**
 * @typedef {Object} storyMap
 * @property {String} article
 * @property {String} title
 */

export default {
  name: "DecisionTree",
  components: {
    ASlide,
    APlayIcon,
    YoutubeModal,
    ADownloadIcon, MultipleMarkdownStories, AMouseIcon, AButton, LottieAnimation, BModal
  },
  mixins: [TocAwareSlideMixin],
  toc: {
    toc: 'main',
    description: "Interaktiver\n Entscheidungsbaum",
    options: {
      background: 'light'
    }
  },
  data() {
    return {
      currentStory: null,
      videoVisible: false,
      overallSpeed: 2,
      /**
       * @type {AnimationItem}
       */
      animController: null,
      deciderController: null,
      deciderPlaying: false,
      tocEntry: null,
      container: null,
      playing: null,
      visible: false,
      title: "Tool(s) für Erklärbare KI",
      stories: null,
      segments: null,
      segment: null,
      sequenceComplete: false,
      stopOn: null,
      reachedEnd: true,
      autoplayFirstSegment: true,
      decisionMap: [
        {
          selector: '.btn-decision-domain',
          storyFile: 'story/decisiontree/lottie/audience-complete.json',
          containerClasses: ['hide-story-left', 'with-fadein-left'],
          stopOnFrame: 408
        },
        {
          selector: '.btn-decision-dev',
          storyFile: 'story/decisiontree/lottie/audience-complete.json',
          containerClasses: ['hide-story-right', 'with-fadein-right'],
          stopOnFrame: 797
        }
      ],
      /** @type {Object<String,storyMap>}*/
      storyMap: {
        'btn-story-lime': {
          article: 'story/decisiontree/lime.md',
          title: 'Lime'
        },
        'btn-story-surrogat': {
          article: 'story/decisiontree/surrogat.md',
          title: 'Surrogat'
        },
        'btn-story-shap': {
          article: 'story/decisiontree/shap.md',
          title: 'SHAP'
        },
        'btn-story-whitemodell': {
          article: 'story/decisiontree/whitemodell.md',
          title: 'White Model'
        },
        'btn-story-integratedgradients': {
          article: 'story/decisiontree/integratedgradients.md',
          title: 'Integrated Gradients'
        },
        'btn-story-gradcam': {
          article: 'story/decisiontree/gradcam.md',
          title: 'CAM/GradCAM'
        },
        'btn-story-deeplift': {
          article: 'story/decisiontree/deeplift.md',
          title: 'DeepLIFT'
        },
        'btn-story-lrp': {
          article: 'story/decisiontree/lrp.md',
          title: 'LRP'
        },
        'btn-story-prototypen': {
          article: 'story/decisiontree/prototypen.md',
          title: 'Prototypen'
        },
        'btn-story-counterfactual': {
          article: 'story/decisiontree/counterfactual.md',
          title: 'Counterfactual'
        }
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.playAnimationTo);
    this.$nextTick(() => {
      this.container = this.$refs.lottieContainer;
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.playAnimationTo);
  },
  animation: {
    visibility: [.2, .8],
    totalFrames: 0,
    lastFramePlayed: 0,
  },
  methods: {
    showVideo() {
      this.videoVisible = true;
    },
    playExplainer() {
      if (this.deciderController) {
        this.deciderPlaying = true;
        this.deciderController.play();
      }
    },
    currentContainerVisibility() {
      const e = this.container.getBoundingClientRect(), t = e.top, n = e.height;
      return (window.innerHeight - t) / (window.innerHeight + n);
    },
    playAnimationTo() {
      if (this.container && this.animController && !this.segments.isEmpty()) {
        if (this.playing !== null) {
          return;
        }
        const containerVisibility = this.currentContainerVisibility();

        if (containerVisibility < this.$options.animation.visibility[0] || containerVisibility >= this.$options.animation.visibility[1]) {
          return false;
        }

        const currentFrame = this.animController.currentFrame;
        const targetFrame = Math.ceil(
            (containerVisibility - this.$options.animation.visibility[0]) / (this.$options.animation.visibility[1] - this.$options.animation.visibility[0]) * this.$options.animation.totalFrames)
        ;
        this.direction = (currentFrame < targetFrame) ? 1 : -1;
        if (this.direction < 0) {
          return
        }

        if (!this.segment) {
          this.segment = this.segments.head;
        } else {
          // if the currently set segment is last one, dont do anything
          if (this.segment.element.last === true) {
            return false;
          }
          // prepare next segment
          this.segment = this.segment.next;
        }

        const currentSegment = this.segment.element;

        this.playing = currentSegment;
        this.animController.playSegments([currentSegment.start, currentSegment.end], false);
      }
    },
    initStoryTrigger(container) {
      this.decisionMap.forEach((decisionConf) => {
        let elements = container.querySelectorAll(decisionConf.selector);

        if (elements.length === 0) {
          console.warn("decider-element is not found, but registered: ", decisionConf);
        } else {
          elements.forEach((element) => {
            element.addEventListener('click', () => {
              this.loadTree(decisionConf);
            })
          });
        }
      })
    },
    /**
     * @param {Element} container
     */
    initMarkdownTrigger(container) {
      const elements = container.querySelectorAll(Object.keys(this.storyMap).map((e) => `.${e}`).join(","));
      elements.forEach((ele) => {
        const storiesForButton = this._extractStories(ele.classList);

        ele.addEventListener('click', () => {
          this.loadStoryArticles(storiesForButton);
        });
      })
    },
    _extractStories(classList) {
      const availableStories = Object.keys(this.storyMap);
      const classStories = [];

      availableStories.forEach((storyClass) => {
        if (classList.contains(storyClass)) {
          classStories.push(this.storyMap[storyClass]);
        }
      })

      return classStories;


    },
    clear() {
      this.container.classList.remove('hide-story-left');
      this.container.classList.remove('hide-story-right');
    },
    reveal() {
      this.clear();
      this.reachedEnd = true;
    },
    loadTree(treeConfig) {
      this.playing = null;
      this.animController = null;
      this.currentStory = treeConfig.storyFile;
      this.stopOn = treeConfig.stopOnFrame;
      this.sequenceComplete = false;
      this.clear();
      this.container.classList.add(...treeConfig.containerClasses);
      this.playAnimationTo();
    },
    loadStoryArticles(stories) {
      this.stories = stories;
      this.visible = true;
    },
    /**
     * Updates the markers and should be triggered by lottie-animation
     *
     * @param {AnimationItem}
     * @param {Object[]} markers List of markers
     * @param {Number} markers[].tm start frame of the marked sequence
     */
    updateMarkers({totalFrames}, markers) {
      this.segments = new LinkedList();
      this.segment = null;

      let last = null;
      const lastFrame = totalFrames - 1;

      markers.forEach((marker, index, markers) => {
        let s, e;

        if (index + 1 === markers.length) {
          e = lastFrame;
        } else {
          e = markers[index + 1].tm;
        }

        if (index === 0) {
          s = 0;
        } else {
          s = marker.tm;
        }

        last = this.segments.add({
          start: s,
          end: e,
          last: false
        });
      });

      if (this.segments.isEmpty()) {
        this.segments.add({
          start: 0,
          end: lastFrame,
          last: true
        });
      } else {
        if (last.end !== lastFrame) {
          this.segments.add({
            start: last.end,
            last: true,
            end: lastFrame
          });
        } else {
          last.last = true;
        }
      }

      if (this.animController && this.autoplayFirstSegment) {
        this.playAnimationTo();
      }
    },
    /**
     * @param {AnimationItem} controller
     * @param {Element} container
     */
    setDeciderController(controller, container) {
      this.deciderController = controller;

      this.initStoryTrigger(container);
      this.initMarkdownTrigger(container);
    },
    /**
     * @param {AnimationItem} controller
     * @param {Element} container
     */
    setAnimController(controller, container) {
      this.animController = controller;
      this.$options.animation.totalFrames = controller.getDuration(true);
      this.$options.animation.lastFramePlayed = 0;

      this.initMarkdownTrigger(container);

      controller.addEventListener('enterFrame', (detail) => {
        /**
         * @see lottie-web/BMEnterFrameEvent
         *
         * @type {Object} detail
         * @type {Number} detail.currentTime
         * @type {Number} detail.totalTime
         */

        if (
            this.playing === null ||
            detail.currentTime >= this.playing.end - 1 ||
            detail.currentTime < this.$options.animation.lastFramePlayed
        ) {
          this.playing = null;
          controller.pause();

          console.log(`stopping on ${detail.currentTime}`);
          if (!this.sequenceComplete && this.stopOn && (
              detail.currentTime >= this.stopOn ||
              detail.currentTime >= this.$options.animation.totalFrames - 2
          )) {
            this.sequenceComplete = true;
            controller.goToAndStop(this.$options.animation.totalFrames - 2, true);
            setTimeout(() => this.reveal(), 1000);
          }

          this.reachedEnd = !this.segment.next;
        }

        this.$options.animation.lastFramePlayed = detail.currentTime;
      });
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/colors';
@import '../../assets/scss/variables';

.with-fadein-left .story-left,
.with-fadein-right .story-right {
  transition: opacity 1s ease-out;
  opacity: 1;
}

.hide-story-left {
  .story-left {
    opacity: 0;
  }
}

.hide-story-right {
  .story-right {
    opacity: 0;
  }
}

.decision-explainer {
  transition: opacity 0.4s $default-ease;
  width: 100%;

  & .b-top {
    border-top: dotted 1px $color-pri-3;
  }

  & .b-bottom {
    border-bottom: dotted 1px $color-pri-3;
  }

  transition: opacity 0.5s ease-out;


  .two-third {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    & .two {
      width: 66%;
      flex-grow: 1;
    }

    & .third {
      width: 240px;
      flex-shrink: 0;
      text-align: center;
    }

  }
}

.decision-animation {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;

  .btn-blue, .btn-white, .btn-green, .btn-grey {
    & * {
      cursor: pointer;
    }

    path[fill] {
      transition: fill 0.4s ease-out, stroke 0.3s ease-out;
    }
  }

  .btn-blue:hover, .btn-grey:hover, .btn-green:hover {
    path[fill] {
      fill: $color-pri-1;
    }

    path[stroke] {
      stroke: $color-pri-1;
    }
  }

  .btn-white:hover {
    path[fill] {
      fill: $color-pri-2;
    }
  }

  .btn-interactive:hover {
    cursor: pointer;
  }

  .btn-white:hover {
    fill: black;
    stroke: black;
  }

  .btn-light:hover {
    fill: black;
    stroke: black;
  }

  .btn-blue:hover [fill] {
    fill: green;
  }

  .btn-blue:hover [stroke] {
    stroke: green;
  }

  // decider
  .btn-background-white:hover * {
    fill: blue;
  }

  // element after
  .btn-background-white.btn-text-decision-domain:hover + .btn-text-decision-domain {
    stroke: white;
    fill: white;
  }

  // element before
  .btn-text-decision-domain:hover ~ .btn-background-white.btn-text-decision-domain + {
    fill: blue;
  }

  .btn-background-white.btn-text-decision-dev:hover + .btn-text-decision-dev {
    stroke: white;
    fill: white;
  }
}
</style>