var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"a-slide",class:(" " + (_vm.fix?'mof':'')),style:({
      backgroundColor: _vm.backgroundColor,
      color: _vm.foregroundColor,
      })},[_c('div',{ref:"theContentContainer",class:("sizing-" + (this.sizing) + " " + (_vm.center?'center-content':'') + " " + (_vm.padded?'content-padded':'')),style:({
      maxWidth: _vm.autoAdjust?(_vm.maxWidth + "px"):'unset',
      marginLeft: _vm.autoAdjust?'auto':0,
      marginRight: _vm.autoAdjust?_vm.currentRightMargin:0
      })},[_vm._t("default"),(_vm.showScrollDownIcon && _vm.currentTocSlide)?_c('a-scroll-down-icon',{staticClass:"scroll-down-container",attrs:{"target":_vm.currentTocSlide.siblings.next(),"theme":_vm.currentTocSlide.options.background==='light'?'dark':'light'}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }